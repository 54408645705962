import React, { Component } from 'react';
import {map} from 'lodash'
import {Button, Checkbox, Card, Icon} from "@blueprintjs/core"
import {withErrorBoundary} from '../utils/ErrorBoundary'
// import './index.css'
class FaxRequest extends Component {
  state = {
    active: false
  }

  sendFax = async (recipientPhone) => {
    const live = "https://9fptbbb72e.execute-api.us-east-1.amazonaws.com/v2-2"
    const test = "http://localhost:5001"
    const response = await fetch(live + '/fax', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          r_id: this.props.orderSnapshot.child('r_id').val(),
          order_id: this.props.orderSnapshot.key,
          recipientPhone
        })
      })
    const parsed = await response.json()
    console.log(parsed)
  }
  render() {
    // console.log(this.props)
    if (!this.props.faxRecipients) {
      return null
    }
    let faxRecipients = this.props.faxRecipients
    let faxResults = this.props.orderSnapshot.child('fax').val() || []
    // const driverStatus = this.props.orderSnapshot.child('driverStatus').val()

    const order_id  = this.props.orderSnapshot.key
    const r_id = this.props.orderSnapshot.child('r_id').val()

    return (
      <div className="driver-request">
        {/* Driver Requested: No
        Driver Confirmed: */}
        Send Fax:
        {/* <span className="driver-request__status">{driverStatus}</span> */}
        {map(faxRecipients, ({name, number}, key) => (
          <span key={key}>
            <Button
              // intent={}
              onClick={this.sendFax.bind(this, number)}>{name}</Button>
          </span>
        ))}

        <ul>
          {map(faxResults, ({sid, status, dateCreated, to}) => {return(
            <li>{to}: {status}</li>
          )})}
        </ul>

        <a href={`https://q0447rhtxa.execute-api.us-east-1.amazonaws.com/v2-1/pdf?order_id=${order_id}&r_id=${r_id}`}>preview</a>
        <br/>
        <a href={`https://oe6ad51p3k.execute-api.us-west-1.amazonaws.com/prod/pdf?order_id=${order_id}&r_id=${r_id}`}>preview(beta)</a>
        
      </div>
    );
  }
}

export default withErrorBoundary(FaxRequest, {displayName: "Fax with Error"});
