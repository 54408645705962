import React, { Component } from 'react';
import {Button, Checkbox, Card, Icon} from "@blueprintjs/core"
import {DateTime} from 'luxon'
import {map} from 'lodash'
import './index.css'
class DriverRequest extends Component {
  state = {
    active: false
  }
  getDrivers = () => {
    return Object.assign({}, this.props.drivers, {test: {
      name: "Patil", email: "patil.varvarian@gmail.com"
    }})
  }
  requestDriver = async (key) => {
    // post info to endpoint
    const {paymentType, tips, totals={}, name: customerName, phone: customerPhone,
      deliveryAddress, deliveryInstructions, distanceInMiles, unitNumber, dueTS, pickupTime, deliveryTime, cartType}
        = this.props.orderSnapshot.val()
    const id = this.props.orderSnapshot.key
    const {numberOfMarketPriceItems, totalPrice, discount, invoice, deliveryFeeInCents} = totals
    const {email, name} = this.getDrivers()[key]
    const {deliveryAddress: restaurantAddress, name: restaurantName, shortNameForDeliveryRequests} = this.props.settings
    let isScheduled = Number(cartType === 'DELIVERY' ? deliveryTime : pickupTime)
    // let exactTimeText = moment(dueTS).calendar()
    let exactTimeText = DateTime.fromMillis(Number(dueTS), {
      zone: 'America/Los_Angeles'
    }).toFormat('EEE LLL.dd t')
    let dueText = isScheduled ?
      `⏰ SCHEDULED: ${exactTimeText}` :
      `ASAP Order created at ${exactTimeText}`
    let paymentText = "Collect Cash Total:"
    let tipText = "Has not tipped with credit card."
    if (tips) { tipText = `Tipped: $${Math.floor(tips * 0.97 - 30) / 100} (after cc processing fees.)` }
    if (paymentType === 'CARD') { paymentText = "Already Paid" }
    let subject = `🥑 ${shortNameForDeliveryRequests} ${id}`
    let message = `${dueText}
                  +
                  + To: ${deliveryAddress}
                  +
                  + ${paymentText}: ${invoice}.
                  + Credit Card Tips: ${tipText}.
                  +
                  + Name: ${customerName}
                  + Phone: ${customerPhone}
                  + Add: ${deliveryAddress}
                  + Apt/Unit: ${unitNumber}
                  + Note: ${deliveryInstructions}

                  + Restaurant Add.: ${restaurantAddress}
                  `
    console.log(message)
    const stagePDF = "https://jtdi8qy2n3.execute-api.us-east-1.amazonaws.com/pdf/email"
    const oldStage = "https://hafjqijco3.execute-api.us-east-1.amazonaws.com/test/email"
    const response = await fetch("https://hafjqijco3.execute-api.us-east-1.amazonaws.com/test/email", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, name, message, subject})
      })
    if (response.ok) {
      this.props.orderSnapshot.ref.update({ driverStatus: 'contacted.' })
    } else {
      this.props.orderSnapshot.ref.update({ driverStatus: `error when sending email.`})
    }
  }
  render() {
    console.log(this.props)
    if (!this.props.drivers) {
      return null
    }
    let drivers = this.getDrivers()
    const driverStatus = this.props.orderSnapshot.child('driverStatus').val()

    return (
      <div className="driver-request">
        {/* Driver Requested: No
        Driver Confirmed: */}
        Driver:
        <span className="driver-request__status">{driverStatus}</span>
        {map(drivers, ({name}, key) => (
          <span key={key}>
            <Button
              // intent={}
              onClick={this.requestDriver.bind(this, key)}>{name}</Button>
          </span>
        ))}

      </div>
    );
  }
}

export default DriverRequest;
