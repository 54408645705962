import React, { Component, useState } from 'react';
import {DateTime} from 'luxon'
import {NavLink, withRouter} from 'react-router-dom'

const join = (base, path) => {
  return base.charAt(base.length-1) === '/'
    ? base.slice(0, -1) + '/' + path
    : base + '/' + path
}

export default function OrdersNavigationItem ({orderSnapshot, newOrders, rootURL}) {
  const {r_id, name, dueTS, cartType, c_at, orderNumber, orderAcceptedByShop, pickupReadyTS, printed}
    = orderSnapshot.val() || {}
  const cartIcon = (cartType === 'DELIVERY') ? '🚘' : ''
  const isComplete = (orderAcceptedByShop || pickupReadyTS)
  const completeIcon = isComplete ? '✅' : ''
  const isCompleteClass = isComplete ? ' complete' : ' incomplete'
  const dueDateTime = DateTime.fromMillis(dueTS)
  const createDateTime = DateTime.fromMillis(c_at)
  const displayDate = ((dueTS - Date.now()) > 1.2 * 60 * 60 * 1000)
  const displayPrinted = (printed) ? '🖨' : ''

  const dateString = dueDateTime.toFormat('MMM.d')
  const timeString = (r_id === 'charm') ? 
    dueDateTime.setZone('America/Los_Angeles').toFormat('t')
    :
    dueDateTime.toFormat('t')
  const creationString = createDateTime.toFormat('MMM.d t')
  const isNewOrder = newOrders[orderSnapshot.key]

  const orderURL = join(rootURL, orderSnapshot.key)

  return(<NavLink
    className={"orders-nav__item" + isCompleteClass}
    to={orderURL}
    >
    {isNewOrder &&
      <div className="new-order-label">
        <span className="text">new</span>
      </div>
    }
    {!isNewOrder && !isComplete &&
      <div className="need-action-order-label">
        <span className="text">need action</span>
      </div>
    }
    <div className={"status" + isCompleteClass}>
      #{orderNumber}{cartIcon}{displayPrinted}
    </div>
    <div className="name">
      {orderSnapshot.child('name').val()}
    </div>
    {displayDate && <div className="date">
      {dateString}
    </div>}
    <div className="time">
      {timeString}
    </div>
    {/* <div className="time">
      {creationString}
    </div> */}
  </NavLink>)
}
