import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAEXs_DO5S9lyY1pPIz40rn9mP0g-186Q8",
  authDomain: "afa-orders.firebaseapp.com",
  databaseURL: "https://afa-orders.firebaseio.com",
  projectId: "afa-orders"
};
firebase.initializeApp(config);

export default firebase
