function localized (stringOrObject) {
  if (typeof stringOrObject === 'string') {
    return stringOrObject
  } else if (typeof stringOrObject === 'object') {
    return stringOrObject.en || 'n/a'
  } else {
    return 'n/a'
  }
}

export default localized
