import React, { Component } from 'react';
class SnapshotNoteEditor extends Component {
  state = {
    disabled: (this.props.clickButtonToEdit === false) ? false : true
    // inputVal
  }
  onChange = (e) => {
    this.props.snapshot.ref.set(e.target.value)
  }
  render() {
    const snapshot = this.props.snapshot
    const label = this.props.label || this.props.snapshot.key
    const clickButtonToEdit = this.props.clickButtonToEdit
    return (
      <div className="input-group">
        <label htmlFor="">{label}</label>
        {this.state.disabled ?
        snapshot.val() || ''
        :
        <textarea
          cols={50}
          rows={5}
          value={snapshot.val() || ''}
          onChange={this.onChange}
          disabled={this.state.disabled}
          className="fb-patheditor">
        </textarea>}
        {(clickButtonToEdit !== false) && <button onClick={() => this.setState({disabled: false})}>edit</button>}
      </div>
    );
  }
}

export default SnapshotNoteEditor;
