import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(this.props.name, error, info);
  }

  render() {
    // console.log('rendering error boun')
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>fax module error</div>;
    }
    return this.props.children;
  }
}

const withErrorBoundary = (Component, {displayName}) => {

  if (displayName) { ErrorBoundary.displayName = displayName }
  return(
    (props) =>
    <ErrorBoundary>
      <Component {...props}></Component>
    </ErrorBoundary>
  )
}

export default ErrorBoundary
export {withErrorBoundary}
