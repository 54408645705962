import React, { Component, useState } from 'react';
import {sortBy} from 'lodash'
import './orders-navigation.scss'
import {DateTime} from 'luxon'
import {NavLink, withRouter} from 'react-router-dom'
import OrdersNavigationItem from './OrdersNavigationItem'

function categorizeOrdersSnapshotByDueTS(snapshot) {
  // PURE Function
  const currentTS = Date.now()
  const OLD_THRESHOLD_HOURS = 2
  const OLD_THRESHOLD_MS =  OLD_THRESHOLD_HOURS * 1000 * 3600
  const HIDE_THRESHOLD_MS = 11 * 60 * 60 * 1000
  const HIDE_TS = currentTS - HIDE_THRESHOLD_MS

  const oldOrders = []
  const currentOrders = []
  snapshot.forEach((orderSnapshot) => {
    const dueTS = orderSnapshot.child('dueTS').val()
    const isMarkedAsComplete = orderSnapshot.child('orderComplete').val()

    if (dueTS < HIDE_TS) {
      return
    } else if (isMarkedAsComplete) {
      // console.log('order is complete')
      oldOrders.push(orderSnapshot)
      return
    } else if ((currentTS - dueTS) > OLD_THRESHOLD_MS) {
      oldOrders.push(orderSnapshot)
      return
    } else {
      currentOrders.push(orderSnapshot)
      return
    }
  })
  const sortedCurrentOrders = sortBy(currentOrders, [(order) => {
    const {dueTS, cartType, deliveryTime, pickupTime} = order.val()
    const scheduledTS = ((cartType === 'DELIVERY') ? deliveryTime : pickupTime)
    const isScheduled = Number(scheduledTS)
    if (isScheduled) {
      return (cartType === 'DELIVERY') ? (dueTS - 60 * 60 * 1000) : (dueTS - 30 * 60 * 1000)
    } else {
      return dueTS
    }
  }])
  const numOfOldOrders = oldOrders.length
  return {oldOrders, numOfOldOrders, currentOrders: sortedCurrentOrders}
}

function OrdersNavigation ({snapshot, newOrders, rootURL}) {
    const [showOldOrders, setShowOldOrders] = useState(false)

    if (!snapshot) { return (null) }

    const {
      oldOrders,
      numOfOldOrders,
      currentOrders
    } = categorizeOrdersSnapshotByDueTS(snapshot)

    return (
      <div className="orders-nav">

        <div className="section-header">{showOldOrders ?
            <button onClick={() => setShowOldOrders(false)}>Hide</button> :
            <button onClick={() => setShowOldOrders(true)}>Older...</button>}</div>

        {(showOldOrders) && oldOrders.map((orderSnapshot) => {
          return(
            <OrdersNavigationItem
              orderSnapshot={orderSnapshot}
              newOrders={newOrders}
              rootURL={rootURL}
              key={orderSnapshot.key}
            />
          )
        })}

        <div className="section-header">
          <h3>Now</h3>
        </div>

        {currentOrders.map((orderSnapshot) => {
          return(
            <OrdersNavigationItem
              orderSnapshot={orderSnapshot}
              newOrders={newOrders}
              rootURL={rootURL}
              key={orderSnapshot.key}
             />
          )
        })}
      </div>
    );

}


export default withRouter(OrdersNavigation);
