import React from 'react'
import {DateTime} from 'luxon'
import firebase from '../firebase'
import {useState, useEffect} from 'react'
import ReactJson from 'react-json-view'
import "./status.scss"

const mapFirebaseChildren = (snapshot, func) => {
  let _ = []
  let index = 1
  snapshot.forEach((s) => {
    _.push(func(s, index))
    index++
  })
  return _
}

function methodToDisplayTitle (token) {
  switch (token) {
    case "FAX":
      return 'fax'
    case "DRIVER_EMAIL":
      return 'driver email'
    case "POSTMATES_DELIVERY_REQUEST":
      return 'delivery'
    case "SMS_REMINDER":
      return 'sms'
    default:
      return token
  }
}

export default React.memo(function Status ({notifySnap, notify_id, mode='standalone', phone}) {
  console.log('notify id:', notify_id)
  const [notifySnapshot, setNotifySnapshot] = useState(null)
  useEffect(() => {
    if (!notify_id) { return }
    const notifyRef = firebase.database().ref(`notify`).child(notify_id)
    notifyRef.on('value', (snapshot) => {
      console.log('getting notify')
      setNotifySnapshot(snapshot)
      console.log(snapshot.val())
      return function () { notifyRef.off() }
    })
  }, [notify_id])
  notifySnap = notify_id ? notifySnapshot : notifySnap
  if (!notifySnap || !notifySnap.exists()) { return null }

  const order_id = notifySnap.child('order_id').val()
  const r_id     = notifySnap.child('r_id').val()
  const cartType     = notifySnap.child('cartType').val()
  // console.log('bug😀', order_id, r_id)
  // const orderRef = firebase.database().ref('platformOrders').child(r_id).child(order_id)
  const placeRequestTS = notifySnap.child('placeRequestTS').val()
  const isDone = !!notifySnap.child('done').val()
  const isDoneClass = isDone ? ' done' : ''
  return(
    <div
      className={"order-rep status" + isDoneClass}
      key={notifySnap.key}>

      {(mode === "standalone") &&
        <div className="header">
          {isDone && <span>✅</span>}
          <h2>{cartType} {notifySnap.child('r_id').val()}</h2>
          <div>due:{Number(notifySnap.child('dueTS').val()) && DateTime.fromMillis(notifySnap.child('dueTS').val()).toFormat('MMM.dd t')}</div>
          <div>{notifySnap.child('order_id').val()}</div>
          {placeRequestTS && <div>placed:{DateTime.fromMillis(placeRequestTS).toFormat('t')}</div>}
        </div>
      }



      {(notifySnap.child('init').val() !== true) &&
        <div>...</div>
      }

      <ul className="tasks">
        {mapFirebaseChildren(notifySnap.child('tasks'), function (taskSnapshot) {
          const {done, status, method, address, tracking_url} = taskSnapshot.val()
          return(
            <li className="task" key={taskSnapshot.key}>
              <span className="task-name">{methodToDisplayTitle(method)} </span>
              {/* <span>done: {done ? 'Yes' : 'No'} </span> */}
              <span className="task-status"> {status}</span>
                {((method.includes('FAX')) || (method === 'POSTMATES_DELIVERY_REQUEST')) &&
                  (mode === 'standalone') &&
                  <button onClick={() => {
                    taskSnapshot.child('RequestId').ref.set(null)
                    taskSnapshot.child('submitSuccess').ref.set(false)
                    taskSnapshot.child('status').ref.set(false)
                    taskSnapshot.child('done').ref.set(false)
                  }}>Resubmit</button>
                }
                {(method === 'NINJA_DELIVERY_REQUEST') &&
                  (mode === 'standalone') &&
                  <button onClick={() => {
                    taskSnapshot.child('RequestId').ref.set(null)
                    taskSnapshot.child('submitSuccess').ref.set(false)
                    taskSnapshot.child('status').ref.set(false)
                    taskSnapshot.child('method').ref.set('POSTMATES_DELIVERY_REQUEST')
                  }}>Change to Postmates</button>
                }
                {((method === 'POSTMATES_DELIVERY_REQUEST') || (method === 'UBER_DELIVERY_REQUEST')) &&
                  tracking_url &&
                  <div>
                    <a href={tracking_url}>{tracking_url}</a>
                    <br/>
                    {phone && <a href={`sms:${phone}`}>text customer</a>}
                  </div>
                }


                {/* {(mode === 'standalone') && <div className="ninja-food-pickup-time">
                  <p>{taskSnapshot.child('driverPickupTime').val() || ''}</p>
                  {(method === 'NINJA_DELIVERY_REQUEST') && <input type="time" className="time" onBlur={(e) => {
                    const string = (e.target.value) // 17:02
                    const ts = DateTime.fromFormat(string, "HH:mm", {
                      // zone: "America/Los_Angeles"
                    }).toMillis()
                    console.log(ts)
                    orderRef.child('pickupReadyTS').update({
                      bestCaseTS: ts,
                      worstCaseTS: ts
                    })
                  }}/>}
                </div>} */}


              {(mode === 'standalone') &&
                <ClickShow order={parseJSONStrings(taskSnapshot.val())} />
              }
            </li>
          )
        })}
      </ul>
    </div>
  )
})


function ClickShow ({order}) {
  const [showJSON, setShowJSON] = useState(false)
  if (showJSON) {
    return <ReactJson src={order} collapsed={true}></ReactJson>
  } else {
    return (
      <button onClick={() => setShowJSON(true)}>+</button>
    )
  }

}


function parseJSONStrings (taskObj) {
  try {
    const logs = taskObj.logs
    for (const id in logs) {
      if (logs[id].jsonString) {
        logs[id].jsonString = JSON.parse(logs[id].jsonString)
      }
    }
    return taskObj
  } catch {
    return taskObj
  }

}
