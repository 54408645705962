import React, { Component } from 'react';

function formatNumber (phone) {
  if (!phone) {return 'no phone'}
  let numbersOnly = phone.replace(/[^0-9]/g, '')
  let withoutLeadingOne = numbersOnly.replace(/$1/, '')
  return `+1${withoutLeadingOne}`
}

class PhoneLink extends Component {
  render() {
    return (
      <a
        href={`tel:${formatNumber(this.props.phone)}`}
        className="phonelink">
        {this.props.phone}
      </a>
    );
  }
}

export default PhoneLink;
