import React, {Component} from 'react'
import * as Sentry from '@sentry/browser';

export default class AppErrorRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });

    setTimeout(() => {
      window.location.reload()
    }, 1000 * 120)
  }

  render() {
    if (this.state.error) {
        //render fallback UI
        return (
          <div style={{
            margin: "1em",
            fontSize: "2em"
          }}>
            {/* <a onClick={() => window.location.reload()}>
              Refresh
            </a> */}

            <p>App Error. Stay on this screen, the app will refresh itself.</p>

            <p>Call 737-333-1680 to report this error & ask for order details.</p>

          </div>
        );
    } else {
        //when there's not an error, render children untouched
        return this.props.children;
    }
  }
}
