import React, {useState} from 'react'
import PickupTimeEstimate from './PickupTimeEstimate'
import CustomerPickupEstimate from './CustomerPickupEstimate'
import firebase from '../../firebase'
import {Button} from "@blueprintjs/core"


import './index.scss'

const tempSupportedShops = [
  'pablitos', 'pablitostacosnoho', 'pablitostacosburbank', 'pablitostacosvannuys', 'tortas', 'submarineking', 'playa', 'bierhaus',
  'pablitostacosstudio',   'pablitostacosmoorpark', 'elcriollo', 'elcriollovannuys', 'meltit',
  'pablitostacosvictory',   'aleppokitchen', 'romancing', 'dads', 'kiin', 'westernwok', 'sidewalk',
  'tokyoya', 'californiaeatery', 'latarvana', 'sushidon', 'chili-johns', 'mgrill', 'psh', 'phoburbank',
   'larryschilidog', 'leonorsvegetarian', 'tinhorn', 'liquorpalace', 'newdeal', 'harvest', 'romancing', 'costa',
   'lams', 'moores', 'urban', 'taq', 'urban', 'lascasitas', 'lancers', 'lemon', 'losamigos', 'craveoaks', 'electric',
   'mexcocina', 'mrkabob', 'coralcafe', 'knight', 'wildcarvery', 'muiishi', 'hayats', 'goldenchopsticks', 'beachwood', 'poncitlan', 'poncitlanpasa'
 ]

export default function OrderItemsTodoList ({snapshot, hoursBeforeSchedule, settings={}}) {
  const showTodos = (hoursBeforeSchedule < 1)
  const [forceShowTodo, setForceShowTodo] = useState(false)
  const [sendOptionalPostmates, setSendOptionalPostmates] = useState(false)

  const {delivery={}, enablePickupEstimatePicker} = settings
  // console.log('asdf', settings)
  let {selfDeliveryWithOptionalPostmates, deliverWithPostmates} = delivery
  if (!!snapshot.child('pickupReadyTS').val()) {
    selfDeliveryWithOptionalPostmates = false
  }

  const id = snapshot.key
  const {r_id, cartType} = snapshot.val()
  const notify_id = `${id}${r_id}`
  const notifyRef = firebase.database().ref(`notify`).child(notify_id)

  let shoudShowPickupEstimate = enablePickupEstimatePicker && (cartType === 'PICKUP')

  let shouldShowEstimate =
    ((tempSupportedShops.indexOf(r_id) !== -1) || deliverWithPostmates) &&
    (cartType === 'DELIVERY')

  const isAccepted  = !!snapshot.child('orderAcceptedByShop').val()
  const hasEstimate = !!snapshot.child('pickupReadyTS').val()
  if (selfDeliveryWithOptionalPostmates && !sendOptionalPostmates) {
    shouldShowEstimate = false
  }
  let complete = (shouldShowEstimate || shoudShowPickupEstimate) ? hasEstimate : isAccepted
  // if (shoudShowPickupEstimate)
  const completeClass = complete ? ' complete' : ''

  if (showTodos || forceShowTodo || complete) {

    return (
      <section className={"order-item-todos pane" + completeClass}>
        <header className="todo-list-header">
          <h2>{complete ? "Completed Actions" : "Need Action"}</h2>
        </header>

        {shoudShowPickupEstimate ? <CustomerPickupEstimate snapshot={snapshot}></CustomerPickupEstimate> : null}

        {selfDeliveryWithOptionalPostmates && !sendOptionalPostmates && (cartType === 'DELIVERY') &&
          <div>
            <div className="todo-item">
              <Button onClick={() => snapshot.ref.update({orderAcceptedByShop: true})}
                large
                intent={isAccepted ? "success" : "none"}
                icon="tick">Accept & Deliver with Restaurant's Driver</Button>


              </div>
              <div className="todo-item">
                <Button onClick={() => setSendOptionalPostmates(true)}
                  large
                  icon="drive-time"
                  intent="warning"
                  >Request Freelance Driver</Button>
              </div>
          </div>
        }

        {(!shoudShowPickupEstimate && !shouldShowEstimate && !selfDeliveryWithOptionalPostmates) ? <div className="todo-item">
          <Button onClick={() => snapshot.ref.update({orderAcceptedByShop: true})}
            large
            intent={isAccepted ? "success" : "none"}
            icon="tick">Accept Order</Button>
          </div> : null}

          {shouldShowEstimate ?
            <PickupTimeEstimate snapshot={snapshot} /> : null}

          {shouldShowEstimate && selfDeliveryWithOptionalPostmates &&
            <div className="todo-item">
              <Button
                onClick={() => setSendOptionalPostmates(false)}
                large
                intent="warning"
                >
                Cancel
                </Button>
            </div>
          }
          </section>
        )
  } else {
    return(
      <section className="order-item-todos pane">
        <header className="todo-list-header">
          <h2>This order is scheduled more than 1hr in the future</h2>
        </header>
      <div className="todo-item">
          <Button
          onClick={() => {
            setForceShowTodo(true)
          }}
          >Click here if customer wants it earlier</Button>
      </div>
      </section>
    )
  }

}
