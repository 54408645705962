import React, { Component } from 'react';

const FilterRadioGroup = ({ value, currentValue, onChange, label}) => {
  return(
    <div style={{
      display: 'flex',
      alignItems: 'center'
    }}>
      <input type="radio"
        id={`filter-${value}`}
        name="filter"
        value={value}
        checked={currentValue === value ? true : false}
        onChange={onChange}/>
      <label htmlFor={`filter-${value}`} >{label || value}</label>
    </div>
  )
}

export default FilterRadioGroup
