import React from 'react'
import {Button, Icon} from "@blueprintjs/core"
import sendOrderToDomingosPOS from '../temp/sendOrderToDomingosPOS'


export default function ({orderSnapshot}) {
  function removeOrder () {
    orderSnapshot.ref.remove()
  }
  function sendToDom () {
    sendOrderToDomingosPOS(orderSnapshot.val(), orderSnapshot.key)
  }
  function changeDueTS () {
    orderSnapshot.ref.update({
      deliveryTime: Number(orderSnapshot.child('deliveryTime').val()) - 24 * 60 * 60 * 1000
    })
  }
  // if (!process.env.REACT_APP_ADMIN) {
  //   return null
  // }
  return(
    <div>
      {/* <Button onClick={sendToDom}>Send To Dom</Button> */}
      {/* <Button onClick={removeOrder}>Delete</Button> */}
      {/* <Button onClick={changeDueTS}>DueTS minus 1 day</Button> */}

    </div>
  )
}
