import React, { Component } from 'react';

import './CurrentOrders.scss';
import firebase from './firebase'
import FilterRadioGroup from './FilterRadioGroup'
import mobilecheck from './mobilecheck'
import IOSAudioEnabler from './iOSAudioEnabler'
import localized from './localized'
import ModgroupDisplay from './ModgroupDisplay'
import makePhotoURL from './utils/makePhotoURL'
import {Button, Checkbox, Card, Icon} from "@blueprintjs/core"
import InternetStatusBar from './utils/InternetStatusBar'
import PhoneLink from './OrderItem/PhoneLink'
import DriverRequest from './DriverRequest'
import Fax from './Fax'
import Payment from './Payment'
import NotifyStatus from './Notify/Status'
import {format} from 'date-fns'
import {floor} from 'lodash'
import moment from 'moment'
import OrderReadyEstimateEditor from './Settings/OrderReadyEstimateEditor'
import ClickToShow from './utils/ClickToShow'
import OrderItemsTodoList from './Orders/OrderItemsTodoList'



const ORDERS_TIME_FRAME = 2 * 24 * 60 * 60 * 1000 // 3days

class CurrentOrders extends Component {
  constructor() {
    super();
    this.state = {
      orders: [],
      ordersSnapshot: null,
      enableOrdering: false,
      settingsSnapshot: null,
      filter: 'new',
      settings: {}
    }
    this.handleOrderSystemSettingChange = this.handleOrderSystemSettingChange.bind(this)
    this.handleToggleOrderTag = this.handleToggleOrderTag.bind(this)
    this.handleOnFilterChange = this.handleOnFilterChange.bind(this)
  }
  componentDidMount() {
    this.ordersRef = firebase.database().ref(`orders/${this.props.match.params.r_id}`)
    this.ordersRef
      .orderByChild("c_at")
      .startAt(Date.now() - ORDERS_TIME_FRAME) // since 3 days
      .on('value', (snapshot) => {
        let orders = (snapshot.val() || {})
        let newState = []
        for (let id in orders) {
          if (process.env.NODE_ENV === 'development') {console.log(id, orders[id])}
          console.log(orders[id])
          newState.push(Object.assign({}, { id: id, snapshot: snapshot.child(id) }, orders[id]));
        }
        try {
          window.MARIO_COIN.play()
        } catch (e) {

        }
        this.setState({orders: newState, ordersSnapshot: snapshot})
      })

    this.orderSystemSettingsRef = firebase.database().ref(`settings/${this.props.match.params.r_id}`)
    this.orderSystemSettingsRef.on('value', (snapshot) => {
      let {enableOrdering} = snapshot.val() || {}
      this.setState({
        enableOrdering,
        settings: snapshot.val() || {},
        settingsSnapshot: snapshot
      })
      console.log('settings:', snapshot.val())
    })

    this.orderMinutesInterval = setInterval(() => this.forceUpdate(), 60000)
  }
  componentWillUnmount() {
    this.orderMinutesInterval.clearInterval()
    this.ordersRef.off()
    this.orderSystemSettingsRef.off()
  }
  handleOrderSystemSettingChange(event) {
    let orderSystemSettingsRef = firebase.database().ref(`settings/${this.props.match.params.r_id}`)
    orderSystemSettingsRef.update({"enableOrdering": event.target.checked})
  }
  handleToggleOrderTag(id, event) {
    let {name, checked} = event.target
    console.log(name + checked)
    this.ordersRef.child(id).child('tags').update({ [name]: checked })
  }
  handleSetOrderAttribute({id, attr, value}) {
    this.ordersRef.child(id).update({ [attr]: value })
  }
  handleOnFilterChange(event) {
    this.setState({filter: event.target.value})
  }
  handleSetInfoFontSize = (token) => {
    try {
      if (token === 'RESET') {
        window.localStorage['afa-infoFontSize'] = "10"
        this.forceUpdate()
        return
      }
      const currentInfoFontSize = window.localStorage['afa-infoFontSize'] || "10"
      window.localStorage['afa-infoFontSize'] = (token === 'INCREMENT') ?
        Number(currentInfoFontSize) + 1 :
        Number(currentInfoFontSize) - 1
      this.forceUpdate()
    } catch (e) {}
  }
  render() {
    // show oldest first in new orders
    if (!this.state.settingsSnapshot) { return null }

    let orders = this.state.orders.slice().sort((a, b) => (
      (Number(a.dueTS) || Number(a.c_at)) - (Number(b.dueTS) || Number(b.c_at))
    ))

    // show newest first in hidden
    if (this.state.filter === 'hidden') {
      orders.reverse()
    }

    let infoFontSize = "1"
    try {
      infoFontSize = window.localStorage['afa-infoFontSize'] || "10"
    } catch (e) {}

    return (
      <div className="Orders">
        {mobilecheck() ? <IOSAudioEnabler></IOSAudioEnabler> : null}
        <InternetStatusBar></InternetStatusBar>
        <div className="availability">
          <label htmlFor="">Ordering:</label>
          <input type="checkbox" name="enableOrdering" checked={this.state.enableOrdering ? true : false} onChange={this.handleOrderSystemSettingChange}/>
        </div>

        {/* <div><OrderReadyEstimateEditor
          label="Delivery Est: "
          disabled={false}
          clickButtonToEdit={false}
          snapshot={this.state.settingsSnapshot.child('/leadTime/defaultDeliveryEstimate')} /></div> */}
        <div
          style={{
            marginTop: "1em",
            display: "flex", alignItems: "stretch"
          }}
          className="font-size">
          <span>Font Size:{" "}</span>
          <Button onClick={this.handleSetInfoFontSize.bind(this, 'DECREMENT')}>➖</Button>
          <Button onClick={this.handleSetInfoFontSize.bind(this, 'INCREMENT')}>➕</Button>
          <span>({infoFontSize})</span>
          <Button onClick={this.handleSetInfoFontSize.bind(this, 'RESET')}>reset</Button>
        </div>

        <div className="filters">
          <FilterRadioGroup
            value="new"
            label="new orders"
            currentValue={this.state.filter}
            onChange={this.handleOnFilterChange}
          />
          <FilterRadioGroup
            value="hidden"
            label="hidden orders"
            currentValue={this.state.filter}
            onChange={this.handleOnFilterChange}
          />

        </div>




        <ul>
          {orders
            .filter((o) => {
              return ((Date.now() - o.c_at) < ORDERS_TIME_FRAME)
            })
            .filter((o) => {
              let filter = this.state.filter
              if (filter === 'all') {return true}
              if (filter === 'new') {
                return (o.tags && o.tags['hidden']) !== true
              }
              return (o.tags && o.tags[filter]) === true
            })
            .map((order) => {
            let {
              snapshot,
              c_at,
              orderNumber,
              items,
              id,
              name,
              phone,
              cartType,
              deliveryTime,
              pickupTime,
              paymentType,
              deliveryAddress,
              deliveryInstructions,
              distanceInMiles,
              unitNumber,
              chargeResult,
              orderAcceptedByShop,
              tags={},
              isCash,
              tips,
              totals={}} = order
              const {numberOfMarketPriceItems, totalPrice, discount, invoice, deliveryFeeInCents, preTipTotalWithTax} = totals

              if(!items) {
                return null
              }

              const isTestOrder = (name && name.toLowerCase() === 'test')
              const testOrderClass = isTestOrder ? ' test-order' : ''
              // console.log('istest', isTestOrder)


              const scheduledTS = ((cartType === 'DELIVERY') ? deliveryTime : pickupTime)


              const scheduledOrderClass = scheduledTS ? " scheduled" : ""
              // const scheduledText = (cartType === 'DELIVERY') ? `delivery at ${}` : `pick up at ${}`

              // const makeOrderTime = scheduledTS ? scheduledTS - 30 * 60 * 1000 : c_at
              //
              // const minutesAgo =
              //   String((Date.now() - makeOrderTime) / 1000 / 60).split('.')[0]
              // const dateTimeString = format(new Date(c_at), 'MMM,D H:ma')

              return(
                <div
                  key={id}
                  className={"order" + testOrderClass + scheduledOrderClass}>

                  {(true) && <div className="order-acceptance-controls">
                    <Button onClick={this.handleSetOrderAttribute.bind(this, {id, attr: "orderAcceptedByShop", value: true})}
                      intent={orderAcceptedByShop ? "success" : "none"}
                      icon="tick">Accept</Button>
                    {/* <Button onClick={this.handleSetOrderAttribute.bind(this, {id, attr: "orderAcceptedByShop", value: false})}
                      icon="cross">Decline</Button> */}
                  </div>}

                  {orderNumber && <div
                    onClick={() => console.log(JSON.stringify(order))}
                    className="order-number">
                    <sup>#</sup><span className="order-number__number">{orderNumber}</span>
                    <span className="cart-type">{(cartType === 'DELIVERY') ? "Delivery" : "Pick Up"}</span>

                     {/* {(minutesAgo < 120) && <span className="minutes-ago"> - {minutesAgo} mins ago</span>} */}
                     {/* <span className="date-time">- {scheduledText}</span> */}
                  </div>}

                  <Schedule ts={scheduledTS} c_at={c_at} />

                  {/* Order Payment and Rewards Info, credit card,  */}




                  <div className="customer-info">
                    {tips && <span
                      style={{
                        background: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)",
                        borderRadius: "0.2em",
                        fontWeight: "600",
                        padding: "0 0.2em"
                      }}
                      className="tip">tip: ${tips / 100}</span>}
                    <Icon
                      style={{
                        position: 'relative', top: '-0.2em'
                      }}
                      icon="person" iconSize={25}/>
                    <span>{name}</span>
                    <Icon style={{position: 'relative', top: '-0.2em'}} icon="phone" iconSize={25}/><PhoneLink phone={phone} />
                  </div>

                  {(cartType==='DELIVERY') &&
                    <div className="delivery-info">
                      <div className="attr-name">Distance: </div><div>{distanceInMiles}</div>
                      <div className="attr-name">Address: </div><div>{deliveryAddress}</div>
                      <div className="attr-name">Apt/Unit: </div><div>{unitNumber}</div>
                      <div className="attr-name">Note: </div><div>{deliveryInstructions}</div>

                    </div>
                  }



                  {(cartType==='DELIVERY') && <OrderItemsTodoList snapshot={snapshot} />}
                  <ol className="order-items-list">
                    {items.map((item) => {

                      // id is timestamp
                      let {id, name, totalPrice, pickedModgroups, note, category, photo, quantity=1} = item



                      // let category = item["Top Level"]
                      return(
                        <li className="order-item" key={id}>
                          <figure className="thumbnail">
                            <img src={makePhotoURL(photo, {width: 100})} alt=""/>
                          </figure>

                          <div className="legacy-quantity">{quantity}</div>

                          <div
                            style={{
                              fontSize: `${infoFontSize / 10}em`,
                            }}
                            className="text">
                            <Schedule type="product" ts={scheduledTS} />
                            {category && <div className="category">{localized(category)}</div>}
                            <div className="order-item-details">
                              {/* <span className="size">{size} </span> */}
                              <span className="name">{localized(name)} </span>
                              <span className="price">${floor(totalPrice, 2)}</span>
                            </div>

                            <ModgroupDisplay pickedModgroups={pickedModgroups} />

                            {note ? <div className="note">
                              {note}
                            </div> : null}
                          </div>
                        </li>
                      )
                    })}
                  </ol>

                  {totalPrice && <div className="order-totals">
                    <span className="label">Subtotal:</span>
                    <div className="amount">${totalPrice}</div>
                    {(cartType === 'DELIVERY') && <span className="label">Delivery Fee:</span>}
                    {(cartType === 'DELIVERY') && <div className="amount">${(deliveryFeeInCents / 100) || 0}</div>}
                    {(discount > 0) && <span className="label">Discounts:</span>}
                    {(discount > 0) && <div className="amount">${discount || 0}</div>}
                    <span className="label">Taxes:</span>
                    <div className="amount">*</div>
                    <div className="divider"></div>
                    <span className="label grand-total">Grand Total:</span>
                    <div className="amount grand-total">${preTipTotalWithTax || invoice}</div>
                    <span className="label">status:</span>
                    <div className="status">{(paymentType === 'CARD') ? 'Prepaid' : 'Not Paid'}</div>
                  </div>}

                  {totalPrice && <div className="payment-info">
                    {/* <div className="payment-type">
                      {isCash && "Unpaid. Collect payment from customer."}
                    </div> */}
                    {(paymentType === 'CARD') && <Payment chargeResult={chargeResult} tips={tips}></Payment>}
                  </div>}

                  <div className="actions">
                    <label htmlFor="">Hide Order</label>
                    <input type="checkbox" className="button complete"
                      name="hidden"
                      checked={tags.hidden ? true : false}
                      onChange={this.handleToggleOrderTag.bind(this, id)}>

                    </input>

                  </div>
                  <ClickToShow buttonLabel="...legacy actions" className="legacy-actions">
                    {(cartType==='DELIVERY') &&
                      <DriverRequest
                        drivers={this.state.settings.drivers}
                        orderSnapshot={this.state.ordersSnapshot.child(id)}
                        settings={this.state.settings}
                        ></DriverRequest>
                    }

                    <Fax
                      faxRecipients={this.state.settings.faxRecipients}
                      orderSnapshot={this.state.ordersSnapshot.child(id)}
                      settings={this.state.settings}
                      ></Fax>
                  </ClickToShow>

                  <NotifyStatus
                    notify_id={`${id}${this.props.match.params.r_id}`}
                    mode="embeded"
                  />

                  {/* <div className="debug-text">
                    {id}
                  </div> */}
                </div>
              )
          })}
        </ul>
      </div>
    );
  }
}

const Schedule = ({ts, type, c_at}) => {
  let now = Date.now()
  if (ts === 'ASAP' || !ts) {
    if (type === 'product') { return null }
    let scheduledText = `${moment(c_at).fromNow()}`
    let exactTimeText = `${moment(c_at).calendar()}`
    // console.log('minus:', (now + 60 * 60 * 1000) - c_at)
    return(
      <div className="schedule">
        <span className="scheduled-text">
          <span><Icon iconSize={20} icon="time"/>ASAP</span>
          <span> order created </span>
          {/* if within 60 minuts past creation time, display relative time, otherwise absolute time.  */}
          {(c_at < (now - 60 * 60 * 1000)) ?
            <span> {exactTimeText}</span> : <span>{scheduledText}</span>}
        </span>
      </div>
    )
  }
  ts = Number(ts)

  let warningClass = ""
  // for product item reminders, only display if due more than 20 minutes later
  if (type === 'product' && (ts - 20 * 60 * 1000) < now) { return null }
  // add warning color if order is more than 1 hour away
  if (now < ts - 60 * 60 * 1000) { warningClass = "warning-text" }
  //
  let scheduledText = `${moment(ts).fromNow()}`
  if ((ts - 10 * 60 * 1000) < now && now < (ts + 10 * 60 * 1000)) { scheduledText = 'now' }
  // if (now > ts + )
  let exactTimeText = `${moment(ts).calendar()}`


  return(
    <div className="schedule">
      <span className="scheduled-text">

        <span className={warningClass}><Icon iconSize={20} icon="time"/>due {scheduledText}</span>
        {/* display exact time when due time has not yet come, or when due time is more than one hour old*/}
        {(ts > now && type !== 'product') && <span> ({exactTimeText})</span>}
        {(ts < now - 60 * 60 * 1000 && type !== 'product') && <span> ({exactTimeText})</span>}
      </span>
    </div>
  )
}

export default CurrentOrders;
