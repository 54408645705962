import { organizeItems } from '../organizedItems'
const moment = require('moment')

// 1-bold 2-italic 3-bold-italic normal-0

function removeEmoji (text) {
  return text && text.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
}

const SMALL_FONT = 32
const LARGE_FONT = 32
const EXTRA_LARGE_FONT = 40


export default function afaOrderToPrintJson ({utensilsOption, queryStrings, r_id, paymentType, orderNumber, deliveryAddress, unitNumber, deliveryInstructions, tips, deliveryTime, pickupTime, c_at, dueTS, items, name, phone, ts, platformId, cartType, totals={}}) {
  const cmds = []
  const organizedItems = organizeItems(items)
  const scheduledTS = ((cartType === 'DELIVERY') ? deliveryTime : pickupTime)
  const isScheduled = Number(scheduledTS)
  let leadMS = (cartType === 'DELIVERY') ? 60 * 75 * 1000 : 30 * 60 * 1000
  let makeAtText = moment(dueTS - leadMS).format(`M-D h:mmA`)
  let dueAtText = moment(dueTS).format(`M-D h:mmA`)
  let createdAtText = moment(c_at).format(`M-D h:mmA`)
  const queryStringMerged = (queryStrings || []).join(' ')
  const fromQRCodeStand =queryStringMerged.includes('qr-stand') 
  const {totalPrice, discount, invoice,
    deliveryFeeInCents, tax, allServiceFee, preTipTotalWithTax,

  } = totals

  const isVersionTwo = window.location.hostname.includes('appllama3')

  const UNPAID_ORDER_WARNING = (r_id === 'twoguysfromitalyglendale') ? "CASH CASH CASH" : "!!! Attn: Not Paid"

  // prepend test
  // cmds.push(
  //   {  appendBitmapFromURL: `https://5htc7yn9g2.execute-api.us-west-1.amazonaws.com/dev/anyPath/?path=thankyou`, diffusion: true, width: 580, bothScale: true }
  // )

  let shoudPrintTotals = true
  if (['tanghuo', 'pablitostacosburbank'].indexOf(r_id) !== -1) {
    shoudPrintTotals = false
  }



  // cmds.push({
  //   appendBitmapText: queryStringMerged,
  //   fontSize: LARGE_FONT,
  // })

  if (fromQRCodeStand) {
    cmds.push({
      appendBitmapText: "* Customer At Location *",
      fontSize: LARGE_FONT,
    })
    cmds.push({
      appendBitmapText: "  ",
      fontSize: LARGE_FONT,
    })
  }

  if (paymentType === 'CARD') {
    cmds.push({
      appendBitmapText: "Paid in Full",
      fontSize: LARGE_FONT,
    })
  } else {
    cmds.push({
      appendBitmapText: UNPAID_ORDER_WARNING,
      fontSize: 48,
    })
  }

  cmds.push({
    appendBitmapText: "",
  })

  if (r_id === 'twindragon') {

    cmds.push({
      fontSize: LARGE_FONT, alignment: "center",
      appendBitmapText: "Twin Dragon Restaurant"
    })
    cmds.push({
      fontSize: LARGE_FONT, alignment: "center",
      appendBitmapText: "8597 W Pico Blvd"
    })
    cmds.push({
      fontSize: LARGE_FONT, alignment: "center",
      appendBitmapText: "323.655.9805"
    })
    cmds.push({
      fontSize: LARGE_FONT, alignment: "center",
      appendBitmapText: "twindragonca.com (pickup/delivery)"
    })
    cmds.push({
      fontSize: LARGE_FONT, alignment: "center",
      appendQrCode: "https://twindragonca.com"
    })
  }
  cmds.push({
    appendBitmapText: ""
  })

  
  cmds.push({
    fontSize: 48,
    appendBitmapText: `Web #${orderNumber}`
  })
  cmds.push({
    fontSize: LARGE_FONT,
    appendBitmapText: isScheduled ? `SCHEDULED` : 'ASAP'
  })
  isScheduled && cmds.push({
    fontSize: EXTRA_LARGE_FONT,
    appendBitmapText: `Make At: ${makeAtText}`
  });
  isScheduled && cmds.push({
    fontSize: EXTRA_LARGE_FONT,
    appendBitmapText: `${(cartType === 'DELIVERY') ? 'Dlivr' : 'Pickup'} At: ${dueAtText}`
  });
  cmds.push({
    fontSize: EXTRA_LARGE_FONT,
    appendBitmapText: `Created: ${createdAtText}`
  })
  cmds.push({
    fontSize: LARGE_FONT,
    appendBitmapText: ` `
  })
  cmds.push({
    fontSize: LARGE_FONT, alignment: "Center",
    appendBitmapText: `${name}`
  })
  cmds.push({
    font: "A", alignment: "Center",
    appendBitmapText: `${phone}`
  })
  cmds.push({
    fontSize: LARGE_FONT, font: "A", alignment: "Center",
    appendBitmapText: `${cartType}`
  });

  if (cartType === 'DELIVERY') {
    cmds.push({
      fontSize: SMALL_FONT, font: "A", alignment: "Center",
      appendBitmapText: `${deliveryAddress}`
    });
    cmds.push({
      fontSize: SMALL_FONT, font: "A", alignment: "Center",
      appendBitmapText: `${unitNumber}`
    });
    cmds.push({
      fontSize: SMALL_FONT, font: "A", alignment: "Center",
      appendBitmapText: `${deliveryInstructions}`
    });
  }

  cmds.push({
    fontSize: LARGE_FONT,
    appendBitmapText: ` `
  })

  organizedItems.forEach(({products:items, printerTag, printerTagDisplay,numberOfitems}) => {
    if (!isVersionTwo) {
      cmds.push({
        fontSize: EXTRA_LARGE_FONT,
        typeface: "sans-serif-condensed",
        appendBitmapText: `⌯ ${numberOfitems} Item(s) for ${printerTagDisplay} ⌯`,
        textStyle: 1,
      })
    }
    items.forEach(({name, phone, pickedModgroups=[], totalPrice, quantity, note}) => {
      const nameObj = name
      name = (name && name.en) || ''

      // QTY x Name
  
   
      if (['frontierwok', 'test'].includes(r_id)) {
        cmds.push({
          fontSize: EXTRA_LARGE_FONT,
          typeface: "sans-serif-condensed",
          appendBitmapText: `${quantity} x ${removeEmoji(name)} ${nameObj && nameObj.kr}`
        })
      } else {
        cmds.push({
          fontSize: EXTRA_LARGE_FONT,
          typeface: "sans-serif-condensed",
          appendBitmapText: `${quantity} x ${removeEmoji(name)}`
        })
      }
      pickedModgroups.forEach(({name, modifiers}) => {
        if (modifiers) {
          cmds.push({
            typeface: "sans-serif-condensed",
            appendBitmapText: `    ${name}`
          })
          modifiers.forEach(({name}) => {
            cmds.push({
              typeface: "sans-serif-condensed",
              fontSize: EXTRA_LARGE_FONT,
              appendBitmapText: `    - ${name}`
            })
          })
        }
      })
      if (note) {
        cmds.push({
          alignmentment: "Left",
          appendBitmapText: " *note:"
        })
        cmds.push({
          fontSize: LARGE_FONT, alignment: "Left",
          appendBitmapText: `${note}`
        })
      }
      
      if (isVersionTwo) {
        cmds.push({
          x:1, y:1,
          appendBitmapText: "----------------------------"
        })
      }
      cmds.push({
        x:1, y:1,
        appendBitmapLine: "----------------------------"
      })
  
    })
  })




  if (utensilsOption === 'NO_UTENSILS') {
    cmds.push({
      appendBitmapText: "Utensils: NO",
      fontSize: EXTRA_LARGE_FONT,
    })
  }
  if (utensilsOption === 'ADD_UTENSILS') {
    cmds.push({
      appendBitmapText: "Utensils: YES",
      fontSize: EXTRA_LARGE_FONT,
    })
  }
  cmds.push({
    x:1, y:1,
    appendBitmapLine: "----------------------------"
  })

  cmds.push({
    fontSize: LARGE_FONT, alignment: "Center",
    appendBitmapText: ""
  })
  

  if (shoudPrintTotals) {
    if (totalPrice) {
      cmds.push({
        typeface: "sans-serif-condensed",
       fontSize: LARGE_FONT, alignment: "Right",
       appendBitmapText: `subtotal: ${totalPrice}`
     })
    }
    if (tax) {
      cmds.push({
        typeface: "sans-serif-condensed",
       fontSize: LARGE_FONT, alignment: "Right",
       appendBitmapText: `tax:      ${tax}`
     })
    }
    if (discount) {
      cmds.push({
        typeface: "sans-serif-condensed",
       fontSize: LARGE_FONT, alignment: "Right",
       appendBitmapText: `discount: ${discount}`
     })
    }
    if (preTipTotalWithTax) {
      cmds.push({
        typeface: "sans-serif-condensed",
       fontSize: LARGE_FONT, alignment: "Right",
       appendBitmapText: `total:    ${preTipTotalWithTax}`
     })
    }
    if (tips) {
      
      cmds.push({
        typeface: "sans-serif-condensed",
       fontSize: LARGE_FONT, alignment: "Right",
       appendBitmapText: `tips:     ${tips / 100}`
     })
    }
  }

  if (paymentType === 'CARD') {
    cmds.push({
      appendBitmapText: "Paid in Full",
      fontSize: LARGE_FONT,
    })
  } else {
    cmds.push({
      appendBitmapText: UNPAID_ORDER_WARNING,
      fontSize: LARGE_FONT,
    })
  }

  cmds.push({
    fontSize: LARGE_FONT, alignment: "Center",
    appendBitmapText: " "
  })
  cmds.push({
    fontSize: LARGE_FONT, alignment: "Center",
    appendBitmapText: "    END    "
  })
  cmds.push({
    fontSize: LARGE_FONT, alignment: "Center",
    appendBitmapText: "   "
  })
  // cmds.push({
  //   fontSize: LARGE_FONT, alignment: "Center",
  //   appendBitmapText: window.location.hostname
  // })
  cmds.push({
    appendCutPaper: true
  })




  return cmds
}
