import React, {useEffect, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import './newOrderAlert.scss'
import speak from '../utils/speak'
import values from 'object.values'

function pluralize(singular, plural, count) {
  return (count > 1) ? plural : singular
}

export default React.memo(withRouter(function NewOrderAlert ({snapshots, newOrdersDispatch, rootURL, history}) {

  const [shouldHide, setShouldHide] = useState(false)

  snapshots = values(snapshots)

  // if (!snapshots || snapshots.length === 0) { return null }

  console.log('new order alert function')

  const numOfNewOrders = snapshots.length
  const firstOrder = snapshots[0]
  const _ORDER = pluralize("order", "orders", numOfNewOrders)

  useEffect(function timer () {
    console.log('set alert timer 🔔')
    // remove previous timer
    window.alertTimer && window.clearInterval(window.alertTimer)

    console.log('ran use effect for new orders alert (should run once)')

    const alertTimer = window.setInterval(() => {
      if (numOfNewOrders > 0) {
        speak(`${numOfNewOrders} new direct ${_ORDER}.`)
      }
    }, 20 * 1000)

    window.alertTimer = alertTimer

    if (numOfNewOrders > 0) {
      speak(`${numOfNewOrders} new direct ${_ORDER}.`)
    }
    return function clearTimer() {  window.clearInterval(alertTimer) }
  }, [numOfNewOrders])

  function dismiss () {
    setShouldHide(true)
  }

  function viewOrder (url, e) {
    e.preventDefault()
    window.scrollTo(0, 0)
    history.push(url)
  }

  const hasNewOrders = (snapshots.length > 0)
  let link = ''
  if (hasNewOrders) {
    const newSnap = snapshots[0]
    link = `${rootURL}/${newSnap.key}`
  }
  // return (null)
  return(
    <div className={"new-order-alert" + (hasNewOrders ? ' new-order' : '')}>
      <div className={"new-order-alert__content" + (hasNewOrders ? ' new-order' : '')}>
        {hasNewOrders ? <div className="alert-text">
          {numOfNewOrders} new {_ORDER}: <a onClick={viewOrder.bind(this, link)}>
            view next order
            {/* {snapshot.child('name').val()} */}
          </a>

          {/* <ul>
            {snapshots.slice(0,1).map((snapshot) => {
              const url = `${rootURL}/${snapshot.key}`
              return(
                <li>
                  <a onClick={viewOrder.bind(this, url)}>
                    View next new order
                    Order For: {snapshot.child('name').val()}
                    </a>
                </li>
              )
            })}
          </ul> */}
        </div> : 
        <div className="alert-text no-notifications">
          no notification
        </div>
        }
      </div>
    </div>
  )
}))
