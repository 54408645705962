import firebase from '../firebase'
import {useState, useEffect, useRef, useReducer} from 'react'

export default function useSyncSetting(r_id, path, getOnce, defaultValue) {
  const settingRef = firebase.database().ref(`settings/${r_id}/${path}`)
  const [settingValue, setSettingValue] = useState(defaultValue)
  useEffect(function () {
    if (getOnce) {
      settingRef.once('value', function (snap) {
        if (!snap.exists())  {return}
        setSettingValue(snap.val())
      })
    } else {
      console.log('syncing with', path)
      settingRef.on('value', function (snap) {
        if (!snap.exists())  {return}
        setSettingValue(snap.val())
      })
    }
    
  }, [r_id, path])

  return([settingRef, settingValue])
}