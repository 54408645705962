import 'react-app-polyfill/ie11'

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Link }
  from 'react-router-dom'
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import AppErrorRecovery from './utils/AppErrorRecovery'
import { unregister } from './registerServiceWorker';

if (process.env.NODE_ENV !== 'production') {
  Sentry.init({
    dsn: "https://c92e2d0b698e4e129ec3536888a10c4d@sentry.io/1380302"
  });
}

const audio = new Audio('/coin.mp3')
window.MARIO_COIN = audio

ReactDOM.render(
  <AppErrorRecovery>
    <Router>
      <App />
    </Router>
  </AppErrorRecovery>,
  document.getElementById('root'));
// registerServiceWorker();

unregister();
