import {useState, useEffect} from 'react'
import useInterval from './useInterval'
import axios from 'axios'

const testEndpoint = "http://localhost:4005/ping"
// always responds "success"

export default function usePollSettings (r_id) {
  // const [settings, setSettings] = useState({})

  const poll = () => {
    if (process.env.NODE_ENV !== 'development') {
      return
    }
    const params = {
      r_id: 'test',
    }
    axios.get(testEndpoint + `?r_id=${r_id}`)
  }

  useEffect(() => {
    poll()
  }, [0])
  // poll()

  
  // useInterval(poll, 5000)

  // return settings
}
