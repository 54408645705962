import React, { Component } from 'react';
import './ModgroupDisplay.css'
import {round} from 'lodash'

class ModgroupDisplay extends Component {
  componentDidMount() {

  }
  render() {
    let pickedModgroups = this.props.pickedModgroups || []
    // console.log(pickedModgroups)
    return(
      <div className="modgroups">
        {pickedModgroups.map(({name, modifiers=[]}) => {
          if (modifiers.length === 0) {return null}
          return(
            <span className="modgroup">
              <span className="group-name">{name}</span>
              <div className="modifiers">
                {modifiers.map(({name, price}) => (

                    <span className="modifier">
                      <span className="modifier-name">{name}</span>
                      {price ? <span className="modifier-price">${round(price, 2)}</span> : null}
                    </span>

                ))}
              </div>
            </span>
          )
        })}
      </div>
    )
  }
}



export default ModgroupDisplay
