import {useState, useEffect} from 'react'

const settingsEndpoint = "https://7bdiz2tp99.execute-api.us-west-1.amazonaws.com/v10-pretip/settings/"

export default function usePollSettings (r_id) {
  const [settings, setSettings] = useState({})

  useEffect(() => {
    console.log('fetching settings')
    fetch(`${settingsEndpoint}?r_id=${r_id}`)
    .then((response) => response.json())
    .then((settings) => setSettings((settings && settings.text) || {}))
    .catch(e => console.log(e))
  }, [r_id])

  return settings
}
