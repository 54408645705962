import React, { Component } from 'react';
import {Route} from 'react-router-dom'
class SubSection extends Component {
  state = {
    collapsed: true
  }
  render() {
    return(
      <Route path={this.props.path} exact render={() => this.props.children}></Route>
    )
    // return (
    //   <div className="sub-section">
    //     <div>{this.props.label} {this.state.collapsed ?
    //       <button onClick={() => this.setState({collapsed: false})}>show</button> :
    //       <button onClick={() => this.setState({collapsed: true})}>hide</button>}</div>
    //
    //     {!this.state.collapsed && this.props.children}
    //   </div>
    // );
  }
}

export default SubSection;
