import React from 'react'
import {useState} from 'react'

export default function ClickToShow (props) {
  const [visible, setVisibility] = useState(false)
  return(
    <div className={props.className}>
      <a href="#" onClick={(e) => {e.preventDefault(); setVisibility(!visible)}}>
        {props.buttonLabel || 'click to show'}
      </a>

      {visible &&
        <div>
          {props.children}
        </div>
      }
    </div>
  )
}