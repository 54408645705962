import React, { Component } from 'react';
import firebase from  '../firebase'
import {Button, Icon} from "@blueprintjs/core"

// Connected to internet - query an endpoint
// Connected to wifi -
// Connected to firebase -
// https://firebase.google.com/docs/database/web/offline-capabilities

class InternetStatusBar extends Component {
  state = {
    internet: null,
    wifi: null,
    firebase: null,
  }
  componentDidMount() {
    let connectedRef = firebase.database().ref(".info/connected");
    this.connectedRef = connectedRef.on("value", (snap) => {
      if (snap.val() === true) {
        this.setState({firebase: true})
      } else {
        this.setState({firebase: false})
      }
    });
    window.addEventListener('online',  e => this.setState({ wifi: true    }));
    window.addEventListener('offline', e => this.setState({ wifi: false }));
    navigator.onLine ? this.setState({ wifi: true    })
                     : this.setState({ wifi: false    })
  }
  componentWillUnmount() {
    try { this.connectedRef.off() } catch (e) {}
  }
  render() {
    if (this.state.firebase && this.state.wifi) {
      return(
        <div
          style={{
            height: "2px",
            background: "#30c508"
          }}
          className="internetstatusbar good"></div>
      )
    }
    return (
      <div
        style={{
          height: "2px",
          background: "red",
          color: "white"
        }}
        className="internetstatusbar">
        database: {this.state.firebase ? "on" : "off"} |
        wifi: {this.state.wifi ? "on" : "off"}
      </div>
    );
  }
}

export default InternetStatusBar;
