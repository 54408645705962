import React, { Component } from 'react';
class StatementDescriptor extends Component {
  state = {
    disabled: (this.props.clickButtonToEdit === false) ? false : true
    // inputVal
  }
  onChange = (e) => {
    this.props.snapshot.ref.set(e.target.value)
  }
  render() {
    const snapshot = this.props.snapshot
    const label = this.props.label || this.props.snapshot.key
    const clickButtonToEdit = this.props.clickButtonToEdit
    const value = snapshot.val()
    return (
      <div className="input-group">
        <label htmlFor="">{label}</label>
        <input
          value={value}
          onChange={this.onChange}
          disabled={this.state.disabled}
          className="fb-patheditor">
        </input>
        <div className="count">count: {value && value.length}</div>
        {(clickButtonToEdit !== false) && <button onClick={() => this.setState({disabled: false})}>edit</button>}
      </div>
    );
  }
}

export default StatementDescriptor;
