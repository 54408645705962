import React, { useState, useEffect } from 'react';
import firebase from '../firebase'
import SnapshotEditor from './SnapshotEditor'
import SnapshotNoteEditor from './SnapshotNoteEditor'
import SnapArrayEditor from './SnapArrayEditor'
import SnapScheduleEditor from './SnapScheduleEditor'
import StatementDescriptorEditor from './StatementDescriptorEditor'
import SubSection from './SubSection'
import {NavLink} from 'react-router-dom'
import './index.scss'
import SnapshotCheckbox from './SnapshotCheckbox';
import AuthWidget from '../Auth'

const notificationsOptions = [
  {value: "DRIVER_EMAIL"},
  {value: "FAX"},
  {value: "SIGNALWIRE_FAX"},
  {value: "EMAIL_PRINT_PDF"},
  // {value: "SMS_AWS"},
  // {value: "SMS_AWS_IF_NO_ACCEPT"},
  {value: "SMS_REMINDER"},
  {value: "UBER_DELIVERY_REQUEST"},
  {value: "POSTMATES_DELIVERY_REQUEST"},
]

export function Settings (props) {
  const [syncError, setSyncError] = useState(null)
  const [settingsSnapshot, setSettingsSnapshot] = useState(null)
  useEffect(function () {
    const settingsRef = firebase.database().ref(`settings/${props.match.params.r_id}`)
    settingsRef.on('value', (snapshot) => {
      setSettingsSnapshot(snapshot)
      setSyncError(false)
    }, (error) => {
      if (error) { setSyncError(error.message) }
    })

    return () => { settingsRef.off() }
  }, [])

    return (
      <div className="settings-wrapper">
        <AuthWidget></AuthWidget>
        <h1>/settings</h1>
        <nav className="settings-nav">{
          ['covid', 'general','schedule', 'delivery', 'contacts', 'notifications', 'misc', 'donation'].map((path) => (
          <NavLink
            key={path}
            activeClassName="active"
            className="settings-nav-link"
            to={`${props.match.url}/${path}`}>{path}</NavLink>
        ))}</nav>

        {syncError && <div className="sync-error">
            <h2>Can't Access Settings</h2>
            {syncError}
        </div>}

        {settingsSnapshot && <div className="settings">
          <SubSection path={`${props.match.url}/general`}>
            <h2>General</h2>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('enableOrdering')} />
            <SnapshotCheckbox snapshot={settingsSnapshot.child('disableScheduling')} />
            <SnapshotCheckbox snapshot={settingsSnapshot.child('enableUtensilsPicker')} />
            <SnapshotCheckbox snapshot={settingsSnapshot.child('enableOnOffSwitchAndWaitTime')} />
            <SnapshotCheckbox snapshot={settingsSnapshot.child('allowDineIn')} />
            <SnapshotEditor snapshot={settingsSnapshot.child('approxPickupWaitInMinutes')} />
            <SnapshotCheckbox snapshot={settingsSnapshot.child('enablePickupEstimatePicker')} />

            <SnapshotCheckbox snapshot={settingsSnapshot.child('draft')} />
            <SnapshotCheckbox snapshot={settingsSnapshot.child('contactFreeOptions')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('mixedDriver')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('extendedHours')}/>
            <SnapshotNoteEditor
              snapshot={settingsSnapshot.child('news/holiday')}
              type="textarea"
            />
            <SnapshotEditor snapshot={settingsSnapshot.child('cashbackPercentageAsInteger')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('maxCashOrderTotalInCents')}/>

          </SubSection>

          <SubSection path={`${props.match.url}/schedule`}>
            <h2>Dine In Hours</h2>
            <SnapScheduleEditor snapshot={settingsSnapshot.child('hours/dineIn')}
              ></SnapScheduleEditor>
            <h2>Pick Up Hours</h2>
            <SnapScheduleEditor snapshot={settingsSnapshot.child('hours/pickUp')}
              ></SnapScheduleEditor>
            <h2>Delivery Hours</h2>
            <SnapScheduleEditor snapshot={settingsSnapshot.child('hours/delivery')}
              ></SnapScheduleEditor>
          </SubSection>



          <SubSection path={`${props.match.url}/delivery`}>
            <h2>Delivery Terms</h2>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/disableDelivery')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/baseFeeInCents')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/baseFeeMiles')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/feeInCentsPerMile')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/maxDistanceInMiles')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/minimumDeliverySubtotalInCents')}/>

            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/freeDeliveryQualifyingSubtotalInCents')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/smallOrderFee')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/useStepFunction')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/useUberEatsStep')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/threeOverThree')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/fiveOverThree')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/fiveOverFour')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/deliveryCreditOnly')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/deliverWithPostmates')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/selfDeliveryWithOptionalPostmates')}/>

            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/defaultOrderStartsDropoffMinutes')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/notEnoughDriver')}/>
            <SnapshotNoteEditor snapshot={settingsSnapshot.child('delivery/notEnoughDriverText')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('restuarantPhone')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('shortNameForDeliveryRequests')}/>

            <h2>Postmates</h2>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('delivery/leaveAtDoorPostmates')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('delivery/leaveAtDoorPostmatesNote')}/>

            <h2>Delivery Address</h2>
            <div style={{
              backgroundColor: 'yellow',
            }} 
            class="notice">
              INCLUDE CITY STATE ZIPCODE. <br/>
              e.g. 11046 Magnolia Blvd, North Hollywood, CA, 91606<br/>
            </div>
            <SnapshotEditor snapshot={settingsSnapshot.child('deliveryAddress')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('coordinates/lat')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('coordinates/lng')}/>
          </SubSection>

          <SubSection path={`${props.match.url}/notifications`}>
          <SnapArrayEditor snapshot={settingsSnapshot.child('notifications/')}
            template={[
              {key: 'method', type: "select", payload: notificationsOptions  },
              {key: 'address' },
              {key: 'name'    },
            ]}
            ></SnapArrayEditor>
          </SubSection>

          <SubSection path={`${props.match.url}/contacts`}>
              <h2>Drivers</h2>
            <SnapArrayEditor snapshot={settingsSnapshot.child('drivers')}
              template={[
                {key: 'name'},
                {key: 'email'}
              ]}
              ></SnapArrayEditor>

                    <h2>Fax Destinations</h2>
                    <SnapArrayEditor snapshot={settingsSnapshot.child('faxRecipients')}
            template={[
              {key: 'name'},
              {key: 'number'}
            ]}
            ></SnapArrayEditor>
          </SubSection>

          <SubSection path={`${props.match.url}/donation`}>
            <h2>Donations</h2>
            
            <SnapshotCheckbox snapshot={settingsSnapshot.child('donation/enableDonation')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('donation/allowCustomAmount')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('donation/enableDonationThankYouPage')} />
            {/* <SnapshotCheckbox snapshot={settingsSnapshot.child('pretip')}/> */}
            <SnapshotEditor snapshot={settingsSnapshot.child('donation/defaultAmount')}/>
            <SnapshotEditor snapshot={settingsSnapshot.child('donation/donationPrompt')}/>
            {/* <SnapshotEditor snapshot={settingsSnapshot.child('allServiceFeeNote')}/> */}
  
          </SubSection>


          <SubSection path={`${props.match.url}/misc`}>
            <h2>Misc</h2>
            <StatementDescriptorEditor snapshot={settingsSnapshot.child('statement')}/>
            <StatementDescriptorEditor snapshot={settingsSnapshot.child('statementTips')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('disableCash')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('disableCredit')}/>
            <SnapshotCheckbox snapshot={settingsSnapshot.child('deliveryOnly')} />
            <SnapshotCheckbox snapshot={settingsSnapshot.child('pretip')}/>
            <SnapshotEditor   snapshot={settingsSnapshot.child('minimumPickupSubtotalInCents')}/>
            <SnapshotEditor   snapshot={settingsSnapshot.child('allServicePercentage')}/>
            <SnapshotEditor   snapshot={settingsSnapshot.child('allServiceFeeNote')}/>
            <SnapshotEditor
              snapshot={settingsSnapshot.child('taxPercentage')}
            />
          </SubSection>
        </div>}


      </div>
    )

}



export default Settings;
