import React, {useState, useEffect} from 'react'
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import firebase from '../firebase';
require('firebase/auth')

const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (result) => {
        console.log(result)
        return false
      }
    }
  };

export default function () {
  const [isSignedIn, setIsSignedIn] = useState(false)


  useEffect(function observeSignInStatus () {
    const unregisterFunc = firebase.auth().onAuthStateChanged(
        (user) => {
          // console.log("auth: ", user.email)
          setIsSignedIn(!!user)
        }
    );
    return unregisterFunc
  }, [0]) // run once

  if (!isSignedIn) {
    return(
      <FirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />
    )
  } else {
    return(
    <div>
      <button onClick={() => firebase.auth().signOut()}>Sign Out</button>
    </div>
    )
  }

}
