import cloudinary from 'cloudinary-core'

var cl = cloudinary.Cloudinary.new({cloud_name: 'dgps1radv', secure: true});


function makeCloudinaryUrlForPhoto (photo, opts={}) {
  if (!photo) {return 'no-pic'} // if no image is associated with product
  let clType = 'fetch'
  if (photo.indexOf('http') === -1) {
    return cl.url(photo,
      {width: Number(opts.width || 50), crop: "fill"})
  } else {
    return cl.url(photo,
      {width: Number(opts.width || 50), crop: "fill",  type: 'fetch'})
  }


}

export default makeCloudinaryUrlForPhoto
