import React, {useState} from 'react'
import firebase from '../../firebase'
import {Button} from "@blueprintjs/core"
import {DateTime} from 'luxon'

import './PickupTimeEstimate.scss'

function displayMilli(ts) {
  // return ts
  return DateTime.fromMillis(ts).toFormat('EEE hh:mma')
}

export default function PickupTimeEstimate ({snapshot}) {
  const id = snapshot.key
  const {r_id} = snapshot.val()
  const notify_id = `${id}${r_id}`
  const notifyRef = firebase.database().ref(`notify`).child(notify_id)

  const bestCaseTS  = snapshot.child('pickupReadyTS/bestCaseTS').val()
  const worstCaseTS = snapshot.child('pickupReadyTS/worstCaseTS').val()

  // notifyRef.child('placeRequestTS').once('value', (snap) => {
  //   console.log('placeRequestTS:', snap.val() )
  // })

  const estimateOptions = [
    { label: "now",   minutesDelay: [0,  0] },
    { label: "10min",   minutesDelay: [5,  10] },
    { label: "15min",   minutesDelay: [5,  15] },
    { label: "20min",  minutesDelay: [15, 20] },
    { label: "30min",  minutesDelay: [30, 30] },
    { label: "40min",  minutesDelay: [30, 40] },
    { label: "50min",  minutesDelay: [30, 50] },
    { label: "60min",  minutesDelay: [30, 60] },
    { label: "70min",  minutesDelay: [30, 70] },
    { label: "80min",  minutesDelay: [30, 80] },
    { label: "90min",  minutesDelay: [30, 90] },
  ]

  async function commitTS ({bestCaseTS, worstCaseTS}) {
    // const placeRequestTS = Number(worstCaseTS) - 25 * 60 * 1000 // send requests 25min prior
    await notifyRef.child('pickupReadyTS').set(worstCaseTS)
    await snapshot.child('pickupReadyTS').ref.update({
      bestCaseTS,
      worstCaseTS
    })
    fetch(`https://afa-customer-text-notification.glitch.me/msg`, {
      method: 'post', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ orderId: id, shopId: r_id, worstCaseTS, cartType: 'PICKUP' })
    }).then(() => {}).catch((e) => {console.log(e)})
  }
  async function resetPickupTS () {
    await snapshot.child('pickupReadyTS').ref.set(null)
    notifyRef.child('pickupReadyTS').set(null)
  }

  function setMinutesDelay ([bestCaseMinutesDelay, worstCaseMinutesDelay]) {
    const worstCaseMillisecondsDelay = worstCaseMinutesDelay * 60 * 1000
    const bestCaseMillisecondsDelay  = bestCaseMinutesDelay * 60 * 1000
    const nowTS = Date.now()
    const worstCaseTS = nowTS + worstCaseMillisecondsDelay
    const bestCaseTS  = nowTS + bestCaseMillisecondsDelay
    commitTS({worstCaseTS, bestCaseTS})

  }

  return(
    <section className="todo-item">
      <header
        style={{
          fontWeight: 600,
        }}
        >When Can Customer Pickup?</header>
      {/* <p style={{
        marginLeft: "0.3em",
        padding: "0.1em 0.2em",
        borderRadius: "0.2em",
        backgroundColor: "#f6d009",
        display: "inline-block"
      }} className="notice">
        driver needs pickup time
      </p> */}

      {worstCaseTS ?
        <div>
          <div>{displayMilli(worstCaseTS)}</div>
          <Button large onClick={resetPickupTS}>Pick Different Ready Time</Button>
        </div>
        :
        <div>
          {estimateOptions.map(({label, minutesDelay}) => {
            return(
              <Button
                large
                className="minutes-delay-button"
                onClick={setMinutesDelay.bind(this, minutesDelay)}
                >{label}</Button>
            )
          })}
          <div style={{
            display: "flex", alignItems: "center", justifyContent: "flex-end", "marginTop": '0.5em'
          }}>
            <label htmlFor="">
              more:
            </label>
            <input type="datetime-local" className="time" onBlur={(e) => {
              const string = (e.target.value) // 17:02
              console.log(string)
              const ts = DateTime.fromISO(string).toMillis() // does not account for timezone
              if (!Number(ts)) { return }
              // console.log(ts)
              commitTS({worstCaseTS: ts, bestCaseTS: ts})
            }}/>
          </div>
        </div>
      }
    </section>
  )
}
