import React, {useEffect, useState} from 'react'
import firebase from '../firebase'
import OrderItem from '../Orders/OrderItem'


export default function ({match: {params: {r_id, o_id}}}) {
  const [order, setOrder] = useState(null)
  useEffect(() => {
    (async function () {
      const snapshot = await firebase.database().ref(`orders/${r_id}/${o_id}`).once('value')
      if (snapshot.child('redacted').val() === true) {
        const snapshotArchive = await firebase.database().ref(`ordersArchive/${r_id}/${o_id}`).once('value')
        setOrder(snapshotArchive)
      } else {
        setOrder(snapshot)
      }
    })()
    return
  }, [r_id, o_id])

  if(!order) { return (<div>Loading...</div>)}

  console.log(order.val())
  return(
    <OrderItem
      snapshot={order}
    />
  )
}
