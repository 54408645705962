import React, { Component } from 'react';
import "./order-ready-estimate-editor.scss"
class OrderReadyEstimateEditor extends Component {
  increment = () => {
    this.props.snapshot.ref.set((Number(this.props.snapshot.val()) || 20) + 10)
  }
  decrement = () => {
    this.props.snapshot.ref.set((Number(this.props.snapshot.val()) || 20) - 10)
  }
  onChange = (e) => {
    this.props.snapshot.ref.set(e.target.value)
  }
  render() {
    const snapshot = this.props.snapshot
    const label = this.props.label || this.props.snapshot.key
    const clickButtonToEdit = this.props.clickButtonToEdit
    return (
      <div className="order-ready-estimate-editor">
        <div>
          <label htmlFor="">{label}</label>
        </div>
        <div className="button-group">
          <button onClick={this.decrement}>←</button>
          <div className="button-group"><input className="order-ready-estimate" value={snapshot.val() || ''} onChange={this.onChange}/></div>
          <button onClick={this.increment}>➔</button>
        </div>
      </div>
    );
  }
}

export default OrderReadyEstimateEditor;
