import firebase from '../firebase'
import {useState, useEffect, useRef, useReducer} from 'react'
import {keyBy, omit} from 'lodash'
import printOrder from './Print/printOrder'

const ORDERS_TIME_FRAME = 11 * 60 * 60 * 1000 // 11 hours



function getNewOrders (prevOrdersSnapshot, ordersSnapshot) {
  const newOrders = []
  ordersSnapshot.forEach(function (snapshot) {
    // if previous orders does not contain any of the new order snapshot's key
    // these will be new orders since last synchronised
    if (prevOrdersSnapshot.child(snapshot.key).exists()) {
      return
    }
    // check if order needs to be filtered out
    // note that this still triggers firebase order creation functions like slack and SMS
    if (snapshot.child('hideFromStaff').val()) {
      return
    }
    newOrders.push(snapshot)
  })
  return newOrders
}

export default function useSyncOrders (r_id, timeToken='no token') {
  const [ordersSnapshot, setOrdersSnapshot] = useState(null)


  const [newOrders, newOrdersDispatch] = useReducer(function reducer (state, action) {
    switch(action.type) {
      case 'add':
        return Object.assign({}, state, keyBy(action.newOrders, (newOrderSnap) => newOrderSnap.key))
      case 'remove':
        return omit(state, [action.order.key])
    }
  }, {}) //initial state

  const prevOrdersSnapshotRef = useRef(null)

  window.printResult = ({orderId, results}) => {
    // console.log('saving results')
    if (process.env.NODE_ENV === 'development') {
      return
    }
    const ordersRef = firebase.database().ref(`orders/${r_id}`)
    ordersRef.child(orderId).printResult.set(results)
  }

  useEffect(function syncFirebase () {
    // Purpose: initiates sync,
    // then adds later orders to newOrders state
    console.log('🔥 Syncing with firebase for orders...')
    const ordersRef = firebase.database().ref(`orders/${r_id}`)
    const query = ordersRef.orderByChild("dueTS")
                           .startAt(Date.now() - ORDERS_TIME_FRAME)

    query.on('value', function saveNewSnapshotToState (newOrdersSnapshot) {
      // console.log(newOrdersSnapshot.val())

      // find newest order unless first time sync
      if (prevOrdersSnapshotRef.current) {
        const newOrders = getNewOrders(prevOrdersSnapshotRef.current, newOrdersSnapshot)
        try { newOrders.forEach((orderSnap) => {
          if (orderSnap.child('printed').val()) { return }
          printOrder({order: orderSnap.val(), id: orderSnap.key, context: 'NEW_ORDER'})  })
        } catch(e) {}
        newOrdersDispatch({
          type: 'add',
          newOrders
        })
      }

      // Set Last order to "new" for testing
      // if (process.env.NODE_ENV === 'development' && !prevOrdersSnapshotRef.current) {
      //   let testOrders = []
      //   let numOfOrder = 0
      //   newOrdersSnapshot.forEach((s) => {
      //     if (numOfOrder < 2) {
      //       numOfOrder++
      //       testOrders.push(s)
      //     } else {
      //       return
      //     }
      //   })
      //
      //   console.log('simulate adding new order');
      //   (testOrders.length !== 0) && newOrdersDispatch({
      //     type: 'add',
      //     newOrders: testOrders
      //   })
      // }

      prevOrdersSnapshotRef.current = newOrdersSnapshot

      setOrdersSnapshot(newOrdersSnapshot)
    })

    // CLEAN UP
    return function removeFirebaseListener() {
      console.log('🧯 halting firebase orders sync')
      ordersRef.off()
    }
  },
  // Re-run only when r_id changes
  // TODO: rerun every 5 hrs or so to flush old orders
  [r_id, timeToken])

  return [ordersSnapshot, newOrders, newOrdersDispatch]
}
