
const iphones = [
  71/40, 667/375, 368/207, 736/414, 812/375, 896/414
]

export default function mobilecheck() {
  let check = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  
  let ratio = (window.screen.height / window.screen.width)

  const isIphone = iphones.indexOf(ratio) !== -1

  const appVersion = (window && window.appVersion) || 0
  
  // console.log(window.screen, isIphone)

  console.log(`Mobile: ${check}`)
  return check && !isIphone && (appVersion < 1);
}
