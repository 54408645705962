import React, { Component } from 'react';

const mapFirebaseChildren = (snapshot, func) => {
  let _ = []
  let index = 1
  snapshot.forEach((s) => {
    _.push(func(s, index))
    index++
  })
  return _
}

class SnapScheduleEditor extends Component {
  handleTimezoneChange = (e) => {
    this.props.snapshot.child('timezone').ref.set(e.target.value)
  }
  handleCopyFromSchedule = async (e) => {
    const fromScheduleName = e.target.value
    const snapshot = await this.props.snapshot.ref.parent.child(fromScheduleName).once('value')
    this.props.snapshot.ref.set(snapshot.val())
  }
  render() {
    const snapshot = this.props.snapshot
    const isSummary = !!this.props.summary
    return (
      <div className="snap-schedule-editor">
        Timezone:
        {!isSummary ? <select name="" id=""
          onChange={this.handleTimezoneChange}
          value={this.props.snapshot.child('timezone').val() || 'none'}>
          <option value="none">select timezone</option>
          <option value="America/Los_Angeles">Pacific (LA)</option>
          <option value="America/Chicago">Central (Chicago)</option>
          <option value="America/Denver">Mountain (Denver)</option>
          <option value="America/New_York">Eastern (Miami)</option>
        </select> : this.props.snapshot.child('timezone').val() || 'none'}
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
          <DayOfWeekEditor
            isSummary={isSummary}
            snapshot={snapshot.child(day)} />
        ))}

        {!isSummary && <select name="" id="" onChange={this.handleCopyFromSchedule}>
          <option value={null}>copy schedule from...</option>
          {['dineIn', 'pickUp', 'delivery'].map((type) => (
            <option value={type}>{type}</option>
          ))}
        </select>}
      </div>
    );
  }
}

class DayOfWeekEditor extends Component {
  addRange = () => {
    this.props.snapshot.ref.push({lastModified: Date.now()})
  }
  handleCopyFromDay = async (e) => {
    const copyFromDay = e.target.value
    const snapshot = await this.props.snapshot.ref.parent.child(copyFromDay).once('value')
    this.props.snapshot.ref.set(snapshot.val())
  }
  render() {
    const snapshot = this.props.snapshot
    const name = this.props.displayName || snapshot.key
    const isSummary = this.props.isSummary
    return (
      <div className="day-of-week-editor">
        <div className="day-of-week">{snapshot.key}:</div>

        <div className="time-ranges">
          {!snapshot.val() && <div style={{color: "red"}} class="empty">"empty"</div>} 
          {mapFirebaseChildren(snapshot, (childSnapshot) => { return (
            <TimeRangeEditor isSummary={this.props.isSummary} snapshot={childSnapshot}></TimeRangeEditor>
          )})}
        </div>

        {!isSummary && <button onClick={this.addRange}>new range</button>}

        {!isSummary && <select name="" id="" onChange={this.handleCopyFromDay}>
          <option value={null}>copy...</option>
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
            <option value={day}>{day}</option>
          ))}
        </select>}
      </div>
    );
  }
}

class TimeRangeEditor extends Component {
  render() {
    // console.log('TIME EDITOR')
    const snapshot = this.props.snapshot
    const fromS = snapshot.child('from')
    const toS = snapshot.child('to')
    const isSummary = this.props.isSummary
    return (
      <div className="time-range-editor">
        <TimeEditor isSummary={isSummary} snapshot={fromS}></TimeEditor><span className="arrow"> → </span>
        <TimeEditor isSummary={isSummary} snapshot={toS}></TimeEditor>
        {/* <input type="text" value={fromS.val() || ''} onChange={({target: {value}}) => fromS.ref.set(value)}/> ~ */}
        {/* <input type="text" value={toS.val()   || ''} onChange={({target: {value}}) =>   toS.ref.set(value)}/> */}
        {!isSummary && <button onClick={() => snapshot.ref.set(null)}>delete</button>}
      </div>
    )
  }
}

const militaryHourToUS = (hour) => {
  hour = Number(hour)
  if (hour === 0) { return "12am" }
  if (hour > 12) { return `${hour - 12}pm` }
  if (hour === 12) { return "12pm"}
  return(`${hour}am`)
}

class TimeEditor extends Component {
  render() {
    const snapshot = this.props.snapshot
    const hourS = snapshot.child('hour')
    const minS = snapshot.child('minute')
    const isSummary = this.props.isSummary
    if (isSummary) {
      return(
        <div className="time-editor">
          {militaryHourToUS(hourS.val())} {minS.val()}min
        </div>
      )
    }

    return (
      <div className="time-editor">
        <select value={hourS.val()} id="" onChange={(e) => hourS.ref.set(e.target.value)}>
          <option value={null}>-</option>
          {[...Array(24).keys()].map((luxonHourIndex) => {return(
            <option value={luxonHourIndex}>{militaryHourToUS(luxonHourIndex)}</option>
          )})}
        </select>:
        <select value={minS.val()} id="" onChange={(e) =>  minS.ref.set(e.target.value)}>
          <option value={null}>-</option>
          {[...Array(60).keys()].map((luxonMinIndex) => {return(
            <option value={luxonMinIndex}>{luxonMinIndex}</option>
          )})}
        </select>
      </div>
    );
  }
}




export default SnapScheduleEditor;
