import React, { Component } from 'react';
import SnapshotEditor from './SnapshotEditor'
import SnapSelectEditor from './SnapSelectEditor'
import {format} from 'date-fns'

const mapFirebaseChildren = (snapshot, func) => {
  // console.log(snapshot)
  let _ = []
  let index = 1
  snapshot.forEach((s) => {
    _.push(func(s, index))
    index++
  })

  // let newSnap = await snapshot.ref.push().once('value')
  // console.log(newSnap)
  // _.push(func(newSnap))

  return _
}

class SnapArrayEditor extends Component {

  handleNewItem = () => {
    this.props.snapshot.ref.push({ lastModified: Date.now() })
  }
  handleDeleteItem = (snapshot) => {
    const result = window.confirm(`delete ${JSON.stringify(snapshot.val())} ?`)
    if (result) {
      snapshot.ref.remove()
    }
  }
  render() {
    const snapshot = this.props.snapshot
    const template = this.props.template // e.g. ['name', 'email'}

    return (
      <div class="snap-array-editor-wrapper">
        <div className="name">{snapshot.key}</div>
        <div className="snap-array-editor">

          {mapFirebaseChildren(snapshot, (s) => {return(
            <div className="snap-array-item" key={s.key}>
              {template.map(({key, type, payload}) => {
                if (type === 'select') {
                  return(
                    <SnapSelectEditor snapshot={s.child(key)}
                      options={payload} />
                  )
                }

                return(
                  <SnapshotEditor snapshot={s.child(key)}></SnapshotEditor>
                )
              })}

              <button onClick={this.handleDeleteItem.bind(this, s)}>delete</button>
            </div>
          )})}



          <button className="new-item-button" onClick={this.handleNewItem}>New Entry</button>
        </div>
      </div>
    );
  }
}

export default SnapArrayEditor;
