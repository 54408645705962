import React, {useState, useEffect, useRef} from 'react'
import {Button, Checkbox, Card, Icon} from "@blueprintjs/core"
import { format } from 'path';

export default function CancelOrderAction ({snapshot, notifySnap}) {

  const [showCancelForm, setShowCancelForm] = useState(false)
  const [refundRawInput, setRefundRawInput] = useState('') 
  useEffect(() => {
    return () => {
      console.log('will unmount');
    }
  }, []);

  function startCancellation () {
    setShowCancelForm(true)

  }

  function refundNow () {
    const number = Number(refundRawInput)
    if (number >= 0) {
      snapshot.ref.update({
        toDoRefundInCents: number * 100
      }).then(() => {
        setShowCancelForm(false)
      })
    } else {
      alert('enter a number, e.g. "1.5"')
    }
    // orderSnapshot.
    

    // cancel ninja if need be
  }

  if (snapshot.child('canceled').val()) {
    return(
      <div>Order Canceled</div>
    )
  }

  const toDoRefundInCents = snapshot.child('toDoRefundInCents').val()
  const refundActionText = (toDoRefundInCents > 0) ? 
    `Change Refund` : "Refund →"
  return(
    <div>
      { (toDoRefundInCents > 0) &&
        <span>(refunded ${toDoRefundInCents / 100})
      </span>}
      { showCancelForm ?
        <TimerComponent
          seconds={30}
          callback={() => { setShowCancelForm(false) }}
          render={() => {return(
            <form>
              <input type="text" 
              placeholder="enter refund amount"
              value={refundRawInput}
              onChange={(e) => setRefundRawInput(e.target.value)}/>
              <Button
                className="confirm-cancel-button"
              onClick={refundNow}>Confirm Refund</Button>
              <Button
                onClick={() => setShowCancelForm(false)}
              >Cancel</Button>
            </form>
          )}}
        /> :
        <Button
          className="start-cancellation-button"
          onClick={startCancellation}>{refundActionText}</Button>
      }
    </div>
  )
}

function TimerComponent (props) {
  const {seconds, callback} = props
  useEffect(() => {
    console.log('setting timer')
    const timer = setTimeout(() => {
      callback()
    }, seconds * 1000)
    return () => {
      console.log('clearing timer')
      clearTimeout(timer)
    }
  }, [0])

  return(props.render())
}
