
import {groupBy, map, get} from "lodash"
import { capitalize, upperCase, sumBy } from 'lodash'

function printerTagFormat (tag) {
    return tag.replace('p-', '').split('-').map(upperCase).join(" ")
  }

function mergeSameItems (items) {
    // group items, then caculate quantity
    const grouped = groupBy(items, (item) => {
        let allIds = []
        const itemName = get(item, 'name.en', '')
        const itemProductId = get(item, 'productId', '')
        const pickedModgroups = get(item, 'pickedModgroups', [])
        allIds.push(itemName, itemProductId, '~')
        pickedModgroups.forEach((modgroup) => {
            const modGroupName = get(modgroup, 'name', '')
            const modifiers = get(modgroup, 'modifiers', [])
            if (modifiers.length) {
                allIds.push(modGroupName)
                modifiers.forEach((modifier) => {
                    const modifierName = get(modifier, 'name', '')
                    if (modifierName) {
                        allIds.push(modifierName)
                    }
                })
                allIds.push('~')
            }
            
        })
        return allIds.join('')
    })
    const merged = map(grouped, (items, groupingId)=> {
        const combinedQuantity = sumBy(items, ({quantity}) => {
            return Number(quantity) || 1
        })
        const combinedTotalPrice = sumBy(items, ({totalPrice}) => {
            return Number(totalPrice) || 0
        })
        const combinedItem = Object.assign({}, items[0], {
            quantity: combinedQuantity, qty: combinedQuantity, totalPrice: combinedTotalPrice
        })
        return combinedItem
    })
    console.log(merged)
    return merged
}

export function organizeItems (items) {
    const grouped = groupBy(items, function(item) {
        if (item.tags) {
            const tagList = item.tags.split(',')
            const printerTag = tagList.find((tagString) => {
                return tagString.includes('p-')
            })
            // console.log({tagList})
            return printerTag || 'other'
        } else {
            return 'uncategorized'
        }
        
    })

    const groupedArray = map(grouped, function (products, printerTag) {        
        return({
            products, printerTag, printerTagDisplay: printerTagFormat(printerTag),
            mergedProducts: mergeSameItems(products),
            numberOfitems: sumBy(products, (p) => {
                return p.quantity || 1
            })
        })
    })
    return groupedArray
}