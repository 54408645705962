// v0.1
import firebase from 'firebase'
import shortid from 'shortid'

const domApp = firebase.initializeApp({
  apiKey: "AIzaSyB9jsND8YopCf3KjeFwgC28TwBdeaWBiDk",
  authDomain: "pos-frankly-ginger-3.firebaseapp.com",
  databaseURL: "https://pos-frankly-ginger-3.firebaseio.com",
  projectId: "pos-frankly-ginger-3",
}, 'dom-pos')

const actionsRef = domApp.database().ref('/dom-interim/actions')

function convertOrder(order, orderId) {
  const orderProducts = {}
  order.items.forEach(({id, name, totalPrice, note, pickedModgroups}) => {
    name = (name && name.en)
    const mods = {}
    pickedModgroups && pickedModgroups.forEach(({name, modifiers}) => {
      if (!modifiers) { return }
      modifiers.forEach(({name, price}) => {
        const modId = shortid.generate()
        mods[modId] = {
          name,
          price,
          id: modId
        }
      })
    })
    if (note) {
      const noteId = shortid.generate()
      mods[noteId] = {name: `NOTE: ${note}`, price: 0, id: noteId}
    }
    orderProducts[id] = {
      id, name, price: totalPrice, mods
    }
  })
  return {
    id: orderId,
    orderProducts,
    ts: order.c_at,
    orderCustomer: {
      id: shortid.generate(),
      name: order.name,
      address: order.deliveryAddress || '',
      phone: order.phone,
      note: order.note || ''
    },
    paid: order.paid,
    orderType: order.cartType,
    orderNumber: 'web',
    orderMakeTime: order.dueTS,
  }
}

export default async function (order, orderId) {
  if (!orderId) { throw new Error('no order id')}
  const convertedOrder = convertOrder(order, orderId)
  console.log(convertedOrder)
  await actionsRef.push({
    _createTS: Date.now(),
    _uploadTS: Date.now(),
    id: orderId,
    type: "IMPORT_ONLINE_ORDER",
    order: convertedOrder
  })
}
