

export default function speak (text) {
  // if (window.location.host === 'localhost:3001') {
  //   return
  // }

  try {

    try {
      window.MARIO_COIN.play()
    } catch (e) {
      console.log(e)
    }

    var synth = window.speechSynthesis;

    // console.log('speaking...', text)

    var utterThis = new SpeechSynthesisUtterance(text);
      // utterThis.text = text
      // utterThis.volume = 1;
      //  utterThis.rate = 1;
      //  utterThis.pitch = 1;
       // utterThis.lang= "en-US"

    // utterThis.voice =
    // utterThis.pitch = pitch.value;
    // utterThis.rate = rate.value;
    synth.speak(utterThis);
  } catch (e) {
    console.log(e)
  }

}
