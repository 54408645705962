import { divide } from 'lodash';
import React from 'react';
import './ControlCenter.scss';
import useSyncSetting from './useSyncSetting';
import {Alignment, Switch} from "@blueprintjs/core"
/*
  wait-time: this is the estimate time presented to customers during checkout
  pause: this will stop accepting orders - and display a restaurant is busy message
*/
const options = [
  { value: 15, displayText: '15min' },
  { value: 20, displayText: '20min' },
  { value: 30, displayText: '30min' },
  { value: 45, displayText: '45min' },
  { value: 60, displayText: '1 HOUR' },
  { value: 75, displayText: '1hr 15min' },
  { value: 90, displayText: '1hr 30min' },
  { value: 105, displayText: '1hr 45min' },
  { value: 120, displayText: '2 HOURS' },
  { value: 135, displayText: '2hr 15min' },
  { value: 150, displayText: '2hr 30min' },
]

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const now = new Date()
const dayKey = days[now.getDay()]
console.log(dayKey)
const militaryHourToUS = (hour) => {
  hour = Number(hour)
  if (hour === 0) { return "12am" }
  if (hour > 12) { return `${hour - 12}pm` }
  if (hour === 12) { return "12pm"}
  return(`${hour}am`)
}


export default function ControlCenter ({shopId})  {
  const [enableOnlineRef, enableOnline] = useSyncSetting(shopId, 'enableOrdering', false)
  const [pickupWaitRef, pickupWait] = useSyncSetting(shopId, 'approxPickupWaitInMinutes')
  const [printerRef, printerSettings] = useSyncSetting(shopId, 'printer', false, {})
  console.log('pritner settings', printerSettings)
  const [scheduleRef, schedule] = useSyncSetting(shopId, 'hours', false)
  console.log(schedule)
  function changePickupWait (event) { 
    const value = Number(event.target.value) || 15
    pickupWaitRef.set(value)
    pickupWaitRef.parent.child('approxPickupWaitInMinutesLastUpdatedTS').set(Date.now())
  }
  function toggleOnline (event) {
    // console.log('new statu:', event.target.checked)
    enableOnlineRef.set(event.target.checked)
  }
  function printMenu(){
    window.ReactNativeWebView.postMessage(JSON.stringify(
      {
        type: 'print_commands',
        data: {starCommands: [
          // {  appendBitmapText: imageURI },
          {  appendBitmapFromURL: `https://5htc7yn9g2.execute-api.us-west-1.amazonaws.com/dev/menu?shopId=${shopId}`, diffusion: true, width: 580, bothScale: true },

          // {  appendBitmapText: "Twin Dragon Restaurant" },
          // {  appendBitmapText: "Twin Dragon Restaurant" },
          {appendCutPaper: true}
        ]}
      }
    ))
  }
  function printURL(url){
    window.ReactNativeWebView.postMessage(JSON.stringify(
      {
        type: 'print_commands',
        data: {starCommands: [
          // {  appendBitmapText: imageURI },
          {  appendBitmapFromURL: url, diffusion: true, width: 580, bothScale: true },

          // {  appendBitmapText: "Twin Dragon Restaurant" },
          // {  appendBitmapText: "Twin Dragon Restaurant" },
          {appendCutPaper: true}
        ]}
      }
    ))
  }

   async function printDailyReport() {
    console.log('getting reports')
    const response = await fetch(`https://root-iced-theater.glitch.me/daily?shopId=${shopId}`)
    const report = await response.json()
    if (!report || !report.summary) {
      alert('not able to get report from server. tech support: 737-333-1680')
    }
    const summary = report.summary
    const now = new Date()
    window.ReactNativeWebView.postMessage(JSON.stringify(
      {
        type: 'print_commands',
        data: {starCommands: [
          {  appendBitmapText: `Daily Report ${now.toDateString()}`,  },
          {  appendBitmapText: ` `,  },
          {  appendBitmapText: `In Person Cash or Card`,  },
          {  appendBitmapText: ` Pickup Sub   $${summary.cashPickupSubtotal / 100}`,  },
          {  appendBitmapText: ` Delvry Sub   $${summary.cashDeliverySubtotal / 100}`,  },
          {  appendBitmapText: ` Subtotal     $${summary.subtotalInPerson / 100}`,  },
          {  appendBitmapText: ` Tax          $${summary.taxForInPerson / 100}`,  },
          {  appendBitmapText: ` Tips         not tracked`,  },
          {  appendBitmapText: ` Gross        $${summary.totalInPerson / 100}`,  },

          {  appendBitmapText: ``, alignment: 'Center'  },
          {  appendBitmapText: `Prepaid Online w/ Card`,  },
          {  appendBitmapText: ` Pickup Sub   $${summary.ccPickupSubtotal / 100}`,  },
          {  appendBitmapText: ` Delvry Sub   $${summary.ccDeliverySubtotal / 100}`,  },
          {  appendBitmapText: ` Subtotal     $${summary.subtotalOnline / 100}`,  },
          {  appendBitmapText: ` Tax          $${summary.taxForOnline / 100}`,  },
          {  appendBitmapText: ` Total Tips   $${summary.totalCreditCardTips / 100}`,  },
          {  appendBitmapText: ` Pickup Tips  $${(summary.totalCreditCardTips - summary.totalCreditCardDeliveryTips) / 100}`,  },
          {  appendBitmapText: ` Delvry Tips  $${summary.totalCreditCardDeliveryTips / 100}`,  },
          {  appendBitmapText: ` Gross        $${summary.totalCreditCard / 100}`,  },
          // {  appendBitmapText: `Prepaid  Sub ${summary. / 100}`,  },
          // {  appendBitmapText: `Prepaid  Tax ${summary.taxForInPerson / 100}`,  },
          // {  appendBitmapText: `N ${summary.taxForInPerson / 100}`,  },
          // {  appendBitmapText: `In Person Tax ${summary.taxForInPerson / 100}`,  },
          // {  appendBitmapText: `In Person Tax ${summary.taxForInPerson / 100}`,  },
          // {  appendBitmapText: `In Person Tax ${summary.taxForInPerson / 100}`,  },
          // {  appendBitmapText: `In Person Tax ${summary.taxForInPerson / 100}`,  },
          
          {appendCutPaper: true}
        ]}
      }
    ))
    // console.log(report)
  }
  return(
    <div className="control-center-wrapper">
      

     

      <div className="controls">
        <div className="text-support widget">
          For help, text: <br/>737-333-1680<br/>
        </div>
        <span className="wait-time-selector widget">
          wait:
          <select 
            className="time-select"
            onChange={changePickupWait}
            value={pickupWait} type="text">
            <option value={pickupWait}>{pickupWait}min</option>
            <option value={pickupWait}>-- pick new time below --</option>
            {options.map(({value, displayText}) => {
              return(
                <option value={value}>{displayText}</option>
              )
            })}
          </select>

        
        </span>

        {schedule && schedule.pickUp[dayKey] && <div 
          style={{
            fontSize: "0.5em"
          }}
          className="quick-glance">
            {(Object.values(schedule.pickUp[dayKey])).map(({from, to}) => {
              return(
                <div>{militaryHourToUS(from.hour)} {from.minute}min → {militaryHourToUS(to.hour)} {to.minute}min</div> 
              )
            })}
          </div>}
  
        <Switch
          className="online-switch widget"
          large={true}
          innerLabel={"Paused"}
          innerLabelChecked={"Active"}
          checked={!!enableOnline}
          onChange={toggleOnline}
          label="Online"
          inline
         />

        <button
         className="cc-button widget" 
         onClick={printMenu}> 
           🖨ToGo Menu
         </button>

         {<button
         className="cc-button widget" 
         onClick={printDailyReport}> 
           🖨Report
         </button>}

        {printerSettings.thirdparty && printerSettings.thirdparty.active &&
          <button
          className="cc-button widget"  
          onClick={()=> {printURL(printerSettings.thirdparty.url)}}>
            🖨ToGo Slip
          </button>
        }
      </div>



      
    </div>
  )
}