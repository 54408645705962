import React, { Component } from 'react';
export default function SnapSelectEditor ({snapshot, label, options}) {

  label = label || snapshot.key
  const dataType = typeof snapshot.val()
  if (snapshot.val() && dataType === 'object') {
    throw new Error(`used checkbox to edit an Object ${snapshot.val()}, may loose data.`)
  }

  return (
    <div className="input-group">
      <label htmlFor="">{label}</label>
      <select
        style={{font: "inherit"}}
        onChange={(e) => snapshot.ref.set(e.target.value)}
        value={snapshot.val()}
        name="" id="">
        {options.map(({label, value}) => {
          return(
            <option value={value}>{label || value}</option>
          )
        })}
      </select>
    </div>
  );

}
