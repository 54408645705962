import React, { Component } from 'react';
import './index.css'
import {get} from 'lodash'
class Payment extends Component {
  render() {
    let chargeResult = this.props.chargeResult
    let amount = get(chargeResult, 'amount')
    let last4 = get(chargeResult, 'source.last4')
    let tips = this.props.tips
    const toDoRefundInCents = this.props.toDoRefundInCents

    return (
      // <div className="payment">
      //   Paid ${amount / 100} via card: *{last4}
      //
      //   {tips && <div className="tips">
      //     Tipped ${tips / 100} via card: *{last4}
      //     {/* <br/>
      //     (${Math.floor(tips * 0.97 - 30) / 100} after cc process fees) */}
      //   </div>}
      //   {!tips && <div>did not tip</div>}
      //
      //   {(toDoRefundInCents > 0) && <div>
      //     Refund to Card: ${toDoRefundInCents / 100}
      //   </div>}
      //   {(toDoRefundInCents > 0) && <div>
      //     (may take up to 10 business days to reflect on credit card)
      //   </div>}
      // </div>
      <div className="payment">
        Paid via card *{last4}
        {(toDoRefundInCents > 0) && <div>
          Refund to Card: ${toDoRefundInCents / 100}
        </div>}
        {(toDoRefundInCents > 0) && <div>
          (may take up to 10 business days to reflect on credit card)
        </div>}
      </div>
    );
  }
}

export default Payment;
