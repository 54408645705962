import React, { Component } from 'react';
class SnapshotCheckbox extends Component {
  onChange = (e) => {
    const makeChange = window.confirm(`Set ${this.props.snapshot.ref.path} ${e.target.checked ? 'checked' : 'un-checked'}`)
    if (makeChange) {
      this.props.snapshot.ref.set(e.target.checked)
    }
  }
  render() {
    const snapshot = this.props.snapshot
    const dataType = typeof snapshot.val()
    if (snapshot.val() && dataType === 'object') { throw new Error(`used checkbox to edit an Object ${snapshot.val()}, may loose data.`) }
    const label = this.props.label || this.props.snapshot.key

    return (
      <div className="input-group">
        <label htmlFor="">{label}</label>
        <input
          type="checkbox"
          checked={snapshot.val()}
          onChange={this.onChange}/>
      </div>
    );
  }
}

export default SnapshotCheckbox;
