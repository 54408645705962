import afaOrderToStarCommands from './afaOrderToStarCommands'
export default function betaPrint ({order, id, settings, context}) {
  if (window.ReactNativeWebView) {
    let commands = afaOrderToStarCommands(order, settings)
    // commands.push({appendBitmapText: `Hell Ya\n yay`});
    // testStarCommands.push({appendCutPaper:StarPRNT.CutPaperAction.PartialCutWithFeed});
    if ((context === 'NEW_ORDER') && ['test', 'tokyoya', 'phoseason'].includes(order.r_id)) {
      commands = [...commands, ...commands, ...commands]
      
    }

    window.ReactNativeWebView.postMessage(JSON.stringify(
      {
        type: 'print_commands',
        data: {orderId: id, starCommands: commands, macAddresses: null, simulation: null}
      }
    ))
  }
}
